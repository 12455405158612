<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <!-- s::PAGE -->
  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="my-artartist">
                <div class="my-artwork">
                  <div class="hgroup">
                    <h2 class="section-title">아트워크</h2>
                    <p>
                      ARTICLE21에 모든 작품을 저장해 두세요. [리스트 관리]에서 ART & ARTISTS 페이지에서 보여주고 싶은 작품들을 선택할 수 있습니다
                    </p>
                  </div>

                  <div v-if="artWorks.length === 0" class="register">
                    <ul>
                      <li>
                        <button type="button" class="register-artwork" @click="() => openModal(MODE_REGISTER, null)">
                          <img src="@/assets/images/icon/ico_add_b.svg" alt="">
                        </button>
                      </li>
                    </ul>
                  </div>

                  <ul v-else>
                    <li>
                      <button type="button" class="add-artwork" @click="() => openModal(MODE_REGISTER, null)">
                        <img src="@/assets/images/icon/ico_add_w.svg" alt="">
                      </button>
                    </li>
                    <li v-for="(artWork, index) in artWorks" :key="index">
                      <img v-if="artWork" :src="artWork.imageUrl" :alt="artWork.title" style="width: 253px;height: 253px;"/>
                      <div class="mask" style="cursor: pointer;" @click="() => openModal(MODE_VIEW, artWork)">
                        <p>
                          {{ artWork.title }} {{ artWork.theme }}<br>
                          {{ artWork.material }} {{ artWork.size }}<br>
                          {{ artWork.year }}
                        </p>
                      </div>
                    </li>
                  </ul>

                  <div class="button-box">
                    <button @click="artWorkManage" type="button">아트워크 게시 관리</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>

  <!-- s::모달창 -->
  <div class="modal-wrap" v-if="isModalVisible">
    <div class="modal-upload">
      <button type="button" class="close-modalwrap" @click="closeModal">
        <img src="@/assets/images/icon/ico_close_w.svg" alt="">
      </button>
      <div class="form-box">
        <div class="picture-box">
          <div ref="filebox" id="filebox" class="filebox">
            <label for="upload-img">이미지 업로드</label>
            <input type="file" id="upload-img" accept="image/*" @change="loadImage">
            <img ref="output" v-if="modalArtWork" id="output" :src="modalArtWork.imageUrl"/>
          </div>
          <p>
            이미지는 jpg 또는 png 파일, 대용량일수록 좋습니다.<br>
            하단의 정보는 자세하게 입력할수록 협업 등 비즈니스 제안의 확률이 높아집니다.
          </p>
        </div>
        <div class="field-box">
          <ul>
            <li>
              <div class="title">
                <span>작품명</span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="sub-title">
                    <span style="color: red">국문 * </span>
                  </div>
                  <div class="field">
                    <input type="text" v-model="modalArtWork.title"/>
                  </div>
                </div>
                <div class="row">
                  <div class="sub-title">
                    <span>영문</span>
                  </div>
                  <div class="field">
                    <input type="text" v-model="modalArtWork.titleEn"/>
                  </div>
                </div>
              </div>
            </li>
            <li class="colspan">
              <div class="title">
                <span style="color: red">제작 연도 *</span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="field">
                    <select v-model="modalArtWork.year">
                      <option value="">선택</option>
                      <option v-for="year in selectYearRange" :key="year" :value="year">{{ year }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </li>
            <li class="colspan">
              <div class="title">
                <span>사이즈</span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="field">
                    <input type="text" v-model="modalArtWork.size"/>
                  </div>
                </div>
              </div>
            </li>
            <li class="colspan">
              <div class="title">
                <span>재질</span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="field">
                    <input type="text" v-model="modalArtWork.material"/>
                  </div>
                </div>
              </div>
            </li>
            <li class="colspan">
              <div class="title">
                <span>장르</span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="field">
                    <select @change="handleChange" v-model="modalArtWork.genre">
                      <option value="">선택</option>
                      <option v-for="item in ARTIST_ACTIVITY_GENRES" :value="item.value">{{ item.label }}</option>
                    </select>
                    <input type="text" class="etc" v-if="showEtc" v-model="modalArtWork.etc">
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="title">
                <span>테마</span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="sub-title">
                    <span>표현대상</span>
                  </div>
                  <div class="field">
                    <select v-model="modalArtWork.themeSubject">
                      <option value="">선택</option>
                      <option v-for="item in ARTIST_ARTWORK_THEME_SUBJECT" :value="item.value">{{ item.label }}</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="sub-title">
                    <span>시간</span>
                  </div>
                  <div class="field">
                    <select v-model="modalArtWork.themeTime">
                      <option value="">선택</option>
                      <option v-for="item in ARTIST_ARTWORK_THEME_TIME" :value="item.value">{{ item.label }}</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="sub-title">
                    <span>포커스</span>
                  </div>
                  <div class="field">
                    <select v-model="modalArtWork.themeFocus">
                      <option value="">선택</option>
                      <option v-for="item in ARTIST_ARTWORK_THEME_FOCUS" :value="item.value">{{ item.label }}</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="sub-title">
                    <span>방식</span>
                  </div>
                  <div class="field">
                    <select v-model="modalArtWork.themeStyle">
                      <option value="">선택</option>
                      <option v-for="item in ARTIST_ARTWORK_THEME_STYLE" :value="item.value">{{ item.label }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </li>
            <li class="colspan">
              <div class="title">
                <span>작품 설명</span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="field">
                    <input type="text" v-model="modalArtWork.description"/>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="button-box">
            <button type="button" @click="saveArtWork">{{ mode === MODE_VIEW ? '수정하기' : '저장하기' }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- e::모달창 -->

  <!-- s::모달창 리스트 관리-->
  <div class="modal-wrap2" v-if="listModalVisible">
    <div class="sort-panel">
      <button @click="closeModalVisible" type="button" class="close-modalwrap2">
        <img src="@/assets/images/icon/ico_close_w.svg" alt=""/>
      </button>
      <div class="inner-box">
        <div class="sortable-list left-list l-cont">
          <ul ref="leftList" class="sort-list">
            <li
                v-for="(item, index) in leftItems"
                :key="item.id"
                :data-id="item.id"
                @click="toggleActive(index, item.id)"
                :class="{ on: activeIndexes.includes(index) }"
            >
              <i>{{ clickOrder.indexOf(item.id) !== -1 ? clickOrder.indexOf(item.id) + 1 : '' }}</i>
              <img :src="item.imageUrl" alt="" style="width: 176px;height: 176px;"/>
              <div class="mask">
                <p>
                  {{ item.title }}_{{ item.theme }}<br/>
                  {{ item.material }} _ {{ item.size }}<br/>
                  {{ item.year }}
                </p>
              </div>
            </li>
          </ul>
          <div class="btn-box">
            <button @click="moveToRightItems" type="button">추가</button>
          </div>
        </div>
        <div class="sortable-list right-list r-cont">
          <ul ref="rightList" class="sort-list2">
            <li
                v-for="item in rightItems"
                :key="item.id"
                :data-id="item.id"
            >
              <figure>
                <img :src="item.imageUrl" alt="" style="width: 58px;height: 58px;"/>
              </figure>
              <div class="txt">
                <p>
                  {{ item.title }}_{{ item.theme }}<br/>
                  {{ item.material }}<br/>{{ item.size }}
                  {{ item.year }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="button-box">
        <button @click="updateArtworks" type="button">아트워크 게시하기</button>
      </div>
    </div>

  </div>
  <!-- e::모달창 리스트 관리-->

  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>

<script setup>
import {onBeforeUnmount, onMounted, ref, watch} from 'vue';
import 'swiper/swiper-bundle.css';
import {apiClient} from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import $ from "jquery";
import {getSelectYearRange} from "@/common/common-function";
import {
  ARTIST_ACTIVITY_GENRES,
  ARTIST_ARTWORK_THEME_FOCUS,
  ARTIST_ARTWORK_THEME_STYLE,
  ARTIST_ARTWORK_THEME_SUBJECT,
  ARTIST_ARTWORK_THEME_TIME
} from "@/common/common-enum";

const MODE_REGISTER = 'register';
const MODE_VIEW = 'view';

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const mode = ref("");

// 토글 메뉴 함수
const navIconRef = ref(null);
const sideRef = ref(null);
const toggleMenu = () => {
  navIconRef.value.classList.toggle('open');
  sideRef.value.classList.toggle('active');
  document.body.classList.toggle('on');
};

const selectYearRange = getSelectYearRange();
const artist = ref(null);
const artWorks = ref(null);
const modalArtWork = ref(null);
const emit = defineEmits(['mode-change']);
const rightItems = ref([]);
const leftItems = ref([]);
const rightList = ref(null);
const leftList = ref(null);
const activeIndexes = ref([]);
const clickOrder = ref([]);

const selectedImageFile = ref(null);

// 데이터 로드 및 초기화
const loadData = async () => {

  loading.value = true; // 작업이 시작되면 로딩 상태를 시작합니다.

  try {
    const response = await apiClient.get('/v1/artists/me/artworks');
    artWorks.value = response.data;
    response.data.forEach(artWork => {
      artWork.isPublic ? rightItems.value.push(artWork) : leftItems.value.push(artWork);
    });
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};

const output = ref(null);
const filebox = ref(null);
const isModalVisible = ref(false);
const listModalVisible = ref(false);
const showEtc = ref(false);

const loadImage = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedImageFile.value = file;
    output.value.src = URL.createObjectURL(file);
    output.value.onload = () => {
      URL.revokeObjectURL(output.value.src);
      filebox.value.classList.add('on');
    };
  }
};

const openModal = (modalMode, artWork) => {
  if (artWork) {
    modalArtWork.value = artWork;
  } else {
    modalArtWork.value = {
      uuid: null,
      imageUrl: null,
      title: '',
      titleEn: '',
      year: '',
      size: '',
      material: '',
      genre: '',
      etc: '',
      themeSubject: '',
      themeTime: '',
      themeFocus: '',
      themeStyle: '',
      description: '',
    };
    selectedImageFile.value = null;
  }

  mode.value = modalMode;
  showEtc.value = artWork?.genre === 'ETC';
  isModalVisible.value = true;
}

const closeModal = () => {
  isModalVisible.value = false;
}

const handleChange = (event) => {
  showEtc.value = event.target.value === 'ETC';
};

const saveArtWork = async () => {
  if (!validateArtWorkSave()) {
    return;
  }

  const str = mode.value === MODE_REGISTER ? "저장" : "수정";
  if (confirm(str + " 하시겠습니까?")) {
    const formData = new FormData();
    if (selectedImageFile.value) {
      formData.append('image', selectedImageFile.value);
    }
    formData.append('reqDto', new Blob([JSON.stringify(modalArtWork.value)], { type: 'application/json' }));
    try {
      let response;
      if (mode.value === MODE_REGISTER) {
        response = await apiClient.post('/v1/artists/me/artworks', formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        response = await apiClient.put(`/v1/artists/me/artworks/${modalArtWork.value.uuid}`, formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      if(response.status === 200){
        alert(str + " 되었습니다.")
      }
      isModalVisible.value = false;
      location.reload();
    } catch (error) {
      console.error('Error saving artwork:', error);
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};

const validateArtWorkSave = () => {
  if (!modalArtWork.value.imageUrl && !selectedImageFile.value) {
    alert("이미지는 필수 입니다.")
    return false;
  }

  if(modalArtWork.value.genre === '') {
    alert("장르는 필수 입니다.")
    return false;
  }

  if(modalArtWork.value.title === '') {
    alert("작품명 국문은 필수 입니다.")
    return false;
  }

  if(modalArtWork.value.year === '') {
    alert("제작 시기는 필수 입니다.")
    return false;
  }

  if(modalArtWork.value.size === '') {
    alert("사이즈는 필수 입니다.")
    return false;
  }

  if(modalArtWork.value.material === '') {
    alert("재료는 필수 입니다.")
    return false;
  }

  if(modalArtWork.value.themeSubject === '') {
    alert("테마 - 표현대상은 필수 입니다.")
    return false;
  }

  if(modalArtWork.value.themeTime === '') {
    alert("테마 - 시간은 필수 입니다.")
    return false;
  }

  if(modalArtWork.value.themeFocus === '') {
    alert("테마 - 포커스는 필수 입니다.")
    return false;
  }

  if(modalArtWork.value.themeStyle === '') {
    alert("테마 - 방식은 필수 입니다.")
    return false;
  }

  return true;
}

const artWorkManage = () => {
  listModalVisible.value = true;
};

const closeModalVisible = () => {
  emit('mode-change', "none");
  listModalVisible.value = false;
};

// 아트워크 선택 토글 함수
const toggleActive = (index, id) => {
  const i = activeIndexes.value.indexOf(index);
  if (i === -1) {
    activeIndexes.value.push(index);
  } else {
    activeIndexes.value.splice(i, 1);
  }
  const clickIndex = clickOrder.value.indexOf(id);
  if (clickIndex !== -1) {
    clickOrder.value.splice(clickIndex, 1);
  } else {
    clickOrder.value.push(id);
  }
};

// 아트워크 오른쪽으로 이동 함수
const moveToRightItems = () => {
  clickOrder.value.forEach(id => {
    const itemIndex = leftItems.value.findIndex(item => item.id === id);
    if (itemIndex !== -1) {
      rightItems.value.push(leftItems.value[itemIndex]);
      leftItems.value.splice(itemIndex, 1);
    }
  });
  leftItems.value = leftItems.value.map(item => ({ ...item, isPortfolio: false }));
  rightItems.value = rightItems.value.map(item => ({ ...item, isPortfolio: true }));
  clickOrder.value = [];
  activeIndexes.value = [];
};

// 아이템 업데이트 함수
const updateItems = () => {
  const allItems = [...leftItems.value, ...rightItems.value];
  const leftIDs = Array.from($(leftList.value).children()).map(el => $(el).data('id'));
  const rightIDs = Array.from($(rightList.value).children()).map(el => $(el).data('id'));

  leftItems.value = leftIDs.map(id => {
    const item = allItems.find(item => item.id === id);
    if (item) item.isPortfolio = false;
    return item;
  }).filter(item => item !== undefined);

  rightItems.value = rightIDs.map(id => {
    const item = allItems.find(item => item.id === id);
    if (item) item.isPortfolio = true;
    return item;
  }).filter(item => item !== undefined);
};

const updateArtworks = async () => {
  if(confirm("아트워크를 게시 하시겠습니까?")){
    const artworks = rightItems.value.map(item => item.id);
    try {
      await apiClient.put('/artwork/update/public', artworks);
      alert("아트워크가 게시 되었습니다.");
      closeModalVisible();
    } catch (error) {
      alert("서버 접속중 오류가 발생했습니다.");
      console.error(error);
    }
  }
};

const initializeSortable = () => {
  $(leftList.value).sortable({
    connectWith: $(rightList.value),
    stop: () => updateItems()
  });

  $(rightList.value).sortable({
    connectWith: $(leftList.value),
    stop: () => updateItems()
  });
};

const destroySortable = () => {
  if (leftList.value && $(leftList.value).data('ui-sortable')) {
    $(leftList.value).sortable('destroy');
  }
  if (rightList.value && $(rightList.value).data('ui-sortable')) {
    $(rightList.value).sortable('destroy');
  }
};

const reload = () => {
  window.location.reload();
};

// 컴포넌트가 마운트되었을 때 데이터 로드
onMounted(async () => {
  await loadData();
});
onBeforeUnmount(() => {
  destroySortable();
});

watch(listModalVisible, (newVal) => {
  if (newVal) {
    setTimeout(async () => {
      try {
        initializeSortable();
      } catch (error) {
        console.error("Error initializing Swiper", error);
      }
    }, 100);
  } else {
    destroySortable();
  }
});

</script>