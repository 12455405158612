<style scoped>
input:disabled {
  background-color: #f5f5f5;
  color: #999;
  border: 1px solid #ddd;
  cursor: not-allowed;
}
</style>

<template>
  <!-- s::PAGE -->
  <div v-if="mode === 'step1'" class="contents">
    <div class="signup">
      <div class="step">
        <span>회원 가입</span>
        <span>유형 선택</span>
      </div>
      <div class="step1">
        <h2>회원 유형을 선택해 주세요.</h2>
        <p>ARTICLE21 회원은 아티스트와 아티클러로 구분되며, 선택 유형에 따라 가입 절차가 달라집니다.</p>
        <div class="select-box">
          <div class="box">
            <ul>
              <li>나만의 웹페이지와 포트폴리오 제작 툴이 필요하다면</li>
              <li>사람들과 소통하며 새로운 영감을 얻고 싶다면</li>
              <li>아트상품 개발과 유통, 협업 제안을 원한다면</li>
              <li>전시, 행사, 공모 소식을 빠르고 정확하게 얻고 싶다면</li>
            </ul>
            <a href="#" @click.prevent="setModeAndType('step2', 'artist')">
              ARTIST<br>
              아티스트 회원
            </a>
          </div>
          <div class="box">
            <ul>
              <li>다양한 아티스트의 온라인 갤러리를 즐기고 싶다면</li>
              <li>관심 있는 아티스트와 직접 소통하고 싶다면</li>
              <li>특별한 아트상품을 구매하고 싶다면</li>
              <li>일상을 풍요롭게 하는 예술 정보를 원한다면</li>
            </ul>
            <a href="#" @click.prevent="setModeAndType('step2', 'normal')">
              ARTICLER<br>
              일반 회원
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- e::PAGE -->

  <!-- s::PAGE -->
  <div v-if="mode === 'step2'" class="contents">
    <div class="signup">
      <div class="step">
        <span>회원 가입</span>
        <span>약관 동의</span>
      </div>
      <div class="step2">
        <div class="chk-box">
            <span class="checkbox-type2">
              <input type="checkbox" id="allchk" v-model="allChecked" @change="toggleAll">
              <label for="allchk">
                ARTICLE21의 모든 약관을 확인하고 전체 동의합니다. (선택 항목 포함)
              </label>
            </span>
        </div>
        <div class="box">
            <span class="checkbox-type2">
              <input type="checkbox" id="terms" v-model="formData.terms" @change="updateAllChecked">
              <label for="terms">
                (필수) 서비스 이용약관
              </label>
            </span>
          <Terms/>
        </div>
        <div class="box">
            <span class="checkbox-type2">
              <input type="checkbox" id="privacyRequired" v-model="formData.privacyRequired" @change="updateAllChecked">
              <label for="privacyRequired">
                (필수) 개인정보 처리방침
              </label>
            </span>
          <Privacy/>
        </div>
        <div class="box">
            <span class="checkbox-type2">
              <input type="checkbox" id="privacyOptional" v-model="formData.privacyOptional" @change="updateAllChecked">
              <label for="privacyOptional">
                (선택) 개인정보 수집 및 이용
              </label>
            </span>
          <div class="policy-txt">
            선택적 개인정보의 수집 및 이용 목적<br>
            - 수집/이용 목적 : 주문/결제 시 상품 배송<br>
            - 수집 항목 : 구매자 정보, 상품 구매/취소/반품/교환/환불 정보, 수령인 정보<br>
            - 보유/이용 기간 : 회원 탈퇴 후 5일까지
          </div>
        </div>
        <div class="button-box">
          <a href="#" @click.prevent="setModeAndType('step3', type)">다음 단계</a>
        </div>
      </div>
    </div>
  </div>
  <!-- e::PAGE -->

  <!-- s::PAGE -->
  <div v-if="mode === 'step3'" class="contents">
    <div class="signup">
      <div class="step">
        <span>회원 가입</span>
        <span>본인 인증</span>
      </div>
      <div class="certification">
        <h2>본인인증을 해주세요.</h2>
        <p>휴대폰 본인인증을 통해<br>아이디(이메일)을 확인합니다.</p>
        <button @click.prevent="openNicePopup" type="button">휴대폰 본인인증</button>
      </div>
    </div>
  </div>
  <!-- e::PAGE -->

  <!-- s::PAGE -->
  <div v-if="mode === 'step4'" class="contents">
    <div class="signup">
      <div class="step">
        <span>회원 가입</span>
        <span v-if="isArtist">아티스트 회원</span>
        <span v-else>일반 회원</span>
      </div>
      <div class="step3">
        <form>
          <fieldset>
            <div class="form-box">
              <div class="field-row">
                <div class="label">
                  <span>아이디 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <input ref="accountIdRef" v-model="formData.accountId" @input="validateAccountId" type="text" maxlength="20"
                           class="form-control" placeholder="5~20자의 영문, 숫자를 사용해 주세요.">
                  </div>
                  <p v-if="accountIdError" class="txt" style="color: red;">{{ accountIdError }}</p>
                  <p class="txt" v-if="isArtist">아이디는 아티스트 개별 페이지의 도메인으로 사용됩니다.</p>
                  <p class="txt" v-if="isArtist">예) https://article21.kr/alphago</p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>비밀번호 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <div class="pwd-wrap">
                      <input ref="accountPwd1Ref" :type="accountPwd1FieldType" v-model="accountPwd1" maxlength="16"
                             @input="validateAccountPwdForm"
                             placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                      <button @click="toggleAccountPwd1Visibility" :class="{ on: accountPwd1FieldType === 'text'}"
                              type="button" class="btn-pwd"></button>
                    </div>
                  </div>
                  <p v-if="accountPwdError1" class="txt" style="color: red;">{{ accountPwdError1 }}</p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>비밀번호 확인 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <div class="pwd-wrap">
                      <input ref="accountPwd2Ref" :type="accountPwd2FieldType" v-model="accountPwd2" maxlength="16"
                             @input="validateAccountPwdForm"
                             placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                      <button @click="toggleAccountPwd2Visibility" :class="{ on: accountPwd2FieldType === 'text'}"
                              type="button" class="btn-pwd"></button>
                    </div>
                  </div>
                  <p v-if="accountPwdError2" class="txt" style="color: red;">{{ accountPwdError2 }}</p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>이름 (성별) *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <input v-model="nameWithGender" type="text" style="width: 150px;" disabled>
                  </div>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>생년월일 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <input v-model="formData.birthDate" type="text" style="width: 150px;" disabled>
                  </div>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>전화번호 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <input v-model="formData.phoneNum" type="text" style="width: 150px;" disabled>
                  </div>
                  <p v-if="isArtist" class="checkbox-type2" style="padding-top: 5px;">
                    <input type="checkbox" id="chk-sms-agree1" v-model="formData.agreeBusinessSms">
                    <label for="chk-sms-agree1">
                      (필수) 협업 제안 등 비즈니스 전화/SMS 수신에 동의합니다.
                    </label>
                  </p>
                  <p class="checkbox-type2" style="padding-top: 5px;">
                    <input type="checkbox" id="chk-sms-agree2" v-model="formData.agreeMarketingSms">
                    <label for="chk-sms-agree2">
                      (선택) 정보/이벤트 SMS 수신에 동의합니다.
                    </label>
                  </p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>이메일 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <input ref="emailRef" v-model="email1" type="text" style="width: 100px;">
                    @
                    <input v-if="isDirectInput" v-model="email2" @input="directEmailInput" type="text" style="width: 150px;">
                    <select v-model="email3" style="width: 150px;margin-left: 5px;">
                      <option value="">직접입력</option>
                      <option value="naver.com">naver.com</option>
                      <option value="kakao.com">kakao.com</option>
                      <option value="google.com">google.com</option>
                    </select>
                  </div>
                  <p v-if="emailError" class="txt" style="color: red;">{{ emailError }}</p>
                  <p v-if="isArtist" :required="isArtist" class="checkbox-type2" style="padding-top: 5px;">
                    <input type="checkbox" id="chk-email-agree1" v-model="formData.agreeBusinessEmail">
                    <label for="chk-email-agree1">
                      (필수) 협업 제안 등 비즈니스 메일 수신에 동의합니다.
                    </label>
                  </p>
                  <p class="checkbox-type2" style="padding-top: 5px;">
                    <input type="checkbox" id="chk-email-agree2" v-model="formData.agreeMarketingEmail">
                    <label for="chk-email-agree2">
                      (선택) 정보/이벤트 메일 수신에 동의합니다.
                    </label>
                  </p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>주소 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box" style="display: flex;gap: 10px;">
                    <button type="button" class="btn" @click="handleSearchAddress">도로명 주소</button>
                    <div v-if="formData.zoneCode" class="input-txt">({{ formData.zoneCode }}) {{ formData.address }}
                      {{ formData.buildingName }}
                    </div>
                  </div>
                  <div class="input-box" style="padding-top: 5px;">
                    <input v-if="formData.zoneCode" ref="addressRef" v-model="formData.addressDetail" type="text" placeholder="상세주소 입력">
                  </div>
                  <p v-if="addressError" class="txt" style="color: red;">{{ addressError }}</p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                    <span>
                      관심 분야 *<br>
                      (중복 선택 가능)
                    </span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <div v-if="isArtist" class="chk-group item3">
                        <span v-for="(interest, index) in ARTIST_INTERESTS" :key="index" class="checkbox-type2">
                          <input type="checkbox" :id="'chk-ct' + index" :value="interest.value"
                                 v-model="formData.artistInterests">
                          <label :for="'chk-ct' + index">{{ interest.label }}</label>
                          <input v-if="interest.value === 'ETC' && formData.artistInterests.includes('ETC')"
                                 v-model="formData.artistInterestEtc"
                                 type="text" class="etc" placeholder="직접 입력">
                        </span>
                    </div>
                    <div v-else class="chk-group item3">
                        <span v-for="(interest, index) in USER_INTERESTS" :key="index" class="checkbox-type2">
                          <input type="checkbox" :id="'chk-ct' + index" :value="interest.value"
                                 v-model="formData.userInterests">
                          <label :for="'chk-ct' + index">{{ interest.label }}</label>
                          <input v-if="interest.value === 'ETC' && formData.userInterests.includes('ETC')"
                                 v-model="formData.userInterestEtc"
                                 type="text" class="etc" placeholder="직접 입력">
                        </span>
                    </div>
                  </div>
                  <p v-if="interestError" class="txt" style="color: red;">{{ interestError }}</p>
                </div>
              </div>
              <div v-if="isArtist" class="field-row">
                <div class="label">
                    <span>
                        아티스트 *<br>활동 자료
                    </span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <div class="file-group">
                      <div class="filebox2">
                        <label for="certificate">파일업로드</label>
                        <input type="file" id="certificate" @change="handleFileChange" accept=".png, .jpg, .jpeg, .pdf"
                               multiple>
                      </div>
                    </div>
                    <template v-for="(file, index) in authFiles">
                      <div :class="'file-item-' + index" style="display: flex;">
                        <p class="input-txt">{{ file.name }}</p>
                        <button @click="deleteFile(index)" type="button">
                          <img src="@/assets/images/icon/ico_close_b.svg" style="width: 18px;padding-left: 5px;" alt=""/>
                        </button>
                      </div>
                    </template>
                  </div>
                  <p class="txt">
                    아티스트 활동을 확인할 수 있는 자료를 업로드해 주세요.<br>
                    (관련 학교 재학/졸업증명서, 예술인활동증명, 개인전/그룹전 홍보물 등)
                  </p>
                </div>
              </div>
              <div class="button-box">
                <button type="button" v-if="isArtist" @click="saveUser">
                  아티스트 회원 가입
                  <span>활동 자료 검토 후 3일 이내 승인 예정</span>
                </button>
                <button type="button" v-else @click="saveUser">
                  일반 회원 가입
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
  <!-- e::PAGE -->
</template>


<script setup>
import {computed, onMounted, ref, watch} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRouter} from "vue-router";
import Terms from "@/components/policy/Terms.vue";
import Privacy from "@/components/policy/Privacy.vue";
import common from "@/assets/js/common";
import { useNicePopup } from '@/composables/useNicePopup';
import {ARTIST_INTERESTS, USER_INTERESTS} from "@/common/common-enum";

const router = useRouter();
// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const mode = ref("step1");
const type = ref("");
const accountIdRef = ref(null);
const accountPwd1Ref = ref(null);
const accountPwd2Ref = ref(null);
const emailRef = ref(null);
const email1 = ref("");
const email2 = ref("");
const email3 = ref("");
const addressRef = ref(null);

const formData = ref({
  accountId: '',
  accountPwd: '',
  email: '',
  name: '',
  userType: '',
  genderType: '',
  birthDate: '',
  phoneNum: '',
  address: '',
  addressDetail: '',
  buildingName: '',
  zoneCode: '',
  artistInterests: [],
  artistInterestEtc: '',
  userInterests: [],
  userInterestEtc: '',
  uniqueVal: '',
  di: '',
  terms: false,
  privacyRequired: false,
  privacyOptional: false,
  agreeBusinessEmail: false,
  agreeMarketingEmail: false,
  agreeBusinessSms: false,
  agreeMarketingSms: false,
});
const authFiles = ref([]);
const allChecked = ref(false);
const toggleAll = () => {
  const newValue = allChecked.value;
  formData.value.terms = newValue;
  formData.value.privacyRequired = newValue;
  formData.value.privacyOptional = newValue;
};
const updateAllChecked = () => {
  // 모든 체크박스가 선택되면 allChecked도 true로 설정
  allChecked.value = Object.values({
    terms: formData.value.terms,
    privacyRequired: formData.value.privacyRequired,
    privacyOptional: formData.value.privacyOptional
  }).every(value => value);
};

// watch로 checks 객체의 변화를 감지하여 updateAllChecked 호출
watch({
  terms: formData.value.terms,
  privacyRequired: formData.value.privacyRequired,
  privacyOptional: formData.value.privacyOptional
}, updateAllChecked, {deep: true});

const genderTypeMap = {
  MALE: "남",
  FEMALE: "여",
  NONE: "미확인",
};

const nameWithGender = computed(() =>
  {
    console.log("formData.value.genderType: "+formData.value.genderType);
    // 두 값 합쳐서 반환
    return formData.value.name && formData.value.genderType
        ? `${formData.value.name} (${genderTypeMap[formData.value.genderType]})`
        : formData.value.name || '';
  }
);

const isDirectInput = computed(() => email3.value === '')
const directEmailInput = () =>  {
  formData.value.email = email1.value + "@" + email2.value;
}
watch(email3, (newVal) => {
  if (newVal !== '') {
    email2.value = newVal;
  } else {
    email2.value = "";
  }
  formData.value.email = email1.value + "@" + email2.value;
});
const setModeAndType = (newMode, newType) => {
  if (newMode !== "step2") {
    if (!formData.value.terms || !formData.value.privacyRequired) {
      alert('필수 항목을 선택해주세요.');
      return;
    }
  }
  mode.value = newMode;
  if (newType !== "") {
    formData.value.userType = newType;
  }
  window.scrollTo(0, 0);
};

const isArtist = computed(() => formData.value.userType === 'artist');

onMounted(() => {
  loading.value = false;
});

const handleSearchAddress = async () => {
  try {
    const data = await common.searchAddress(); // 검색된 주소 데이터를 address에 저장
    formData.value.address = data.address;
    formData.value.zoneCode = data.zonecode;
    formData.value.buildingName = data.buildingName;
  } catch (error) {
    console.error('주소 검색 중 에러가 발생했습니다.', error);
  }
};

const handleFileChange = (event) => {
  const validExtensions = ['png', 'jpg', 'jpeg', 'pdf'];
  const files = Array.from(event.target.files);
  if (files.length > 7) {
    alert('8개 이상 등록할수 없습니다.');
    return;
  }
  // 유효하지 않은 파일이 있는지 검사
  const invalidFiles = files.filter(file => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return !validExtensions.includes(fileExtension);
  });

  if (invalidFiles.length > 0) {
    alert('PNG, JPG, JPEG, PDF 파일만 업로드 가능합니다.');
    event.target.value = ''; // 입력 값 초기화
    authFiles.value = []; // 파일 목록 초기화
  } else {
    // 유효한 파일들만 authFiles에 저장
    files.forEach(file => authFiles.value.push(file));
  }
};

const deleteFile = (index) => {
  if (confirm("삭제 하시겠습니까?")) {
    authFiles.value.splice(index, 1);
  }
};

const accountPwd1FieldType = ref('password');
const accountPwd2FieldType = ref('password');
const toggleAccountPwd1Visibility = () => {
  accountPwd1FieldType.value = accountPwd1FieldType.value === 'password' ? 'text' : 'password';
};
const toggleAccountPwd2Visibility = () => {
  accountPwd2FieldType.value = accountPwd2FieldType.value === 'password' ? 'text' : 'password';
};

const accountPwd1 = ref('');
const accountPwd2 = ref('');
const accountIdError = ref('');
const accountPwdError1 = ref('');
const accountPwdError2 = ref('');
const emailError = ref('');
const addressError = ref('');
const interestError = ref('');

const validateAccountId = async () => {
  const accountIdRegex = /^(?=.*[a-z])[a-z0-9]{5,20}$/;
  if (!formData.value.accountId) {
    accountIdError.value = '아이디를 입력해 주세요.';
    accountIdRef.value.focus();
    return false;
  } else if (!accountIdRegex.test(formData.value.accountId)) {
    accountIdError.value = '5~20자의 영문 소문자, 숫자만 사용 가능합니다.';
    accountIdRef.value.focus();
    return false;
  } else {
    accountIdError.value = '';
  }
  return true;
}

const validateAccountPwdForm = async () => {
  // Password validation
  const accountPwdRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~]).{8,16}$/;
  if (!accountPwdRegex.test(accountPwd1.value)) {
    accountPwdError1.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    return false;
  } else {
    accountPwdError1.value = '';
  }

  if (accountPwd1.value !== accountPwd2.value) {
    accountPwdError2.value = '비밀번호가 일치하지 않습니다.';
    return false;
  } else {
    accountPwdError2.value = '';
  }
  return true;
}

const validateForm = async () => {
  // Email validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!formData.value.email) {
    emailError.value = '이메일을 입력해 주세요.';
    emailRef.value.focus();
    return false;
  } else if (!emailRegex.test(formData.value.email)) {
    emailError.value = '유효한 이메일 주소를 입력해 주세요.';
    emailRef.value.focus();
    return false;
  } else {
    emailError.value = '';
  }

  if (!formData.value.address || !formData.value.zoneCode) {
    addressError.value = '주소를 입력해 주세요.';
    return false;
  } else if (!formData.value.addressDetail) {
    addressError.value = '상세 주소를 입력해 주세요.';
    addressRef.value.focus();
    return false;
  } else {
    addressError.value = '';
  }

  let interests;
  if (isArtist.value) {
    interests = formData.value.artistInterests;
  } else {
    interests = formData.value.userInterests;
  }

  if (interests.length === 0) {
    alert("관심분야를 1개 이상 선택해 주세요.");
    interestError.value = '관심분야를 1개 이상 선택해 주세요.';
    return false;
  } else {
    interestError.value = '';
  }

  return true;
};


const { open } = useNicePopup();

const openNicePopup = async () => {
  await open({
    type: 'signup',
    onPopupClose: () => {
      isVerify();
    },
  });
};

const isVerify = async () => {
  const verify_data = JSON.parse(localStorage.getItem('verify_data'));
  if(verify_data){
    formData.value.verifyUniqueVal = verify_data.uniqueVal
    formData.value.name = verify_data.name
    formData.value.birthDate = verify_data.birthDate
    formData.value.genderType = verify_data.genderType
    formData.value.phoneNum = verify_data.phoneNum
    formData.value.verifyDi = verify_data.di
    mode.value= 'step4';
    alert("인증이 완료 되었습니다.");
    localStorage.removeItem('verify_data')
  }
};

const saveUser = async () => {
  loading.value = true;

  const isValid1 = await validateAccountId();
  if (!isValid1) {
    loading.value = false;
    return;
  }
  const isValid2 = await validateAccountPwdForm();
  if (!isValid2) {
    loading.value = false;
    return;
  }
  const isValid3 = await validateForm();
  if (!isValid3) {
    loading.value = false;
    return;
  }

  formData.value.accountPwd = accountPwd1.value;

  console.log("isArtist.value: " +isArtist.value);
  try {
    if (isArtist.value) {
      // 아티스트 회원 가입
      if (authFiles.value.length === 0) {
        alert('아티스트 활동을 확인할 수 있는 자료를 업로드해 주세요.');
        loading.value = false;
        return;
      }
      if (!formData.value.agreeBusinessSms || !formData.value.agreeBusinessEmail) {
        alert('비즈니스 SMS 및 이메일 수신에 동의해주세요.');
        loading.value = false;
        return;
      }

      const form = new FormData();
      form.append('reqDto', new Blob([JSON.stringify(formData.value)], { type: 'application/json' }));
      authFiles.value.forEach(file => form.append('files', file));

      await apiClient.post('/v1/auth/artists/signup', form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    } else {
      // 일반 회원 가입
      await apiClient.post('/v1/auth/members/signup', formData.value, {
        headers: { 'Content-Type': 'application/json' },
      });
    }

    alert(
        isArtist.value
            ? "아티스트 회원으로 가입 신청되었습니다.\n3일 이내 승인 메일을 받으실 수 있습니다."
            : "회원가입이 완료 되었습니다."
    );
    await router.push('/'); // 회원가입 성공 시에만 이동
  } catch (error) {
    console.error(error);
    alert(error.response.data || '회원가입 중 오류가 발생했습니다.');
  } finally {
    loading.value = false;
  }
};
</script>