<template>
  <!-- s::로그인 모달창 -->
  <div v-if="mode === 'login'" class="modal-popup modal-signin">
    <button type="button" class="close-modal" @click="closeModal">
      <img src="@/assets/images/icon/ico_close_b.svg" alt=""/>
    </button>
    <form @submit="handleLogin">
      <fieldset>
        <div class="field">
          <div class="label">아이디</div>
          <div class="input-box">
            <input v-model="accountId" @input="validateForm" name="accountId" type="text"/>
          </div>
          <p v-if="accountIdError" class="txt" style="color: red;">{{ accountIdError }}</p>
        </div>
        <div class="field">
          <div class="label">비밀번호</div>
          <div class="input-box">
            <div class="pwd-wrap">
              <input v-model="accountPwd" @input="validateForm" name="accountPwd" :type="accountPwdFieldType"/>
              <button @click="toggleAccountPwdVisibility" type="button" :class="{ on: accountPwdFieldType === 'text'}" class="btn-pwd"></button>
            </div>
          </div>
          <p v-if="accountPwdError" class="txt" style="color: red;">{{ accountPwdError }}</p>
        </div>
        <div class="button-box">
          <button type="submit">로그인</button>
        </div>
      </fieldset>
    </form>
    <div class="link-box">
      <button type="button" class="btn modal-find" @click="changeTabMode('id')">아이디 찾기</button>
      <button type="button" class="btn modal-find" @click="changeTabMode('pwd')">비밀번호 찾기</button>
      <router-link to="/auth/signup" class="btn" @click="closeModal">회원가입</router-link>
    </div>
    <div class="sns-box">
      <OAuthLogin></OAuthLogin>
    </div>
  </div>
  <!-- e::로그인 모달창 -->

  <!-- s::아이디 비번찾기 모달창 -->
  <div class="modal-popup modal-find" v-if="mode === 'findInfo'">
    <div class="find-tab">
      <button type="button" :class="tabMode === 'id'?  'on' : ''" @click="changeTabMode('id')">아이디 찾기</button>
      <button type="button" :class="tabMode === 'pwd'?  'on' : ''" @click="changeTabMode('pwd')">비밀번호 찾기</button>
    </div>
    <div class="find-cont">
      <div id="find1" class="tab-cont" v-if="tabMode === 'id'">
        <h2>
          휴대폰 본인인증을 통해<br>
          아이디를 확인합니다.
        </h2>
        <button @click="openNicePopup" type="button">휴대폰 본인인증</button>
        <p>
          문의 사항은 hello@article21.co.kr로 연락주시기 바랍니다.
        </p>
      </div>
      <div id="find2" class="tab-cont" v-if="tabMode === 'pwd'">
        <h2>
          가입한 <strong>아이디</strong>를 입력한 후<br>
          휴대폰 본인인증을 통해 비밀번호를 변경합니다.
        </h2>
        <div class="input-wrap">
          <input v-model="accountId" type="text">
        </div>
        <button @click="openNicePopup" type="button">휴대폰 본인인증</button>
        <p>
          문의 사항은 hello@article21.co.kr로 연락주시기 바랍니다.
        </p>
      </div>
    </div>
    <div class="button-box">
      <button @click="closeModal" type="button" class="close-modal">닫기</button>
    </div>
  </div>

  <div class="modal-popup modal-find" v-if="tabMode === 'findIdResult'">
    <div class="find-cont">
      <div id="find1" class="tab-cont">
        <h2>고객님의 아이디는<br>{{ accountId }} 입니다.</h2>
      </div>
    </div>
    <div class="button-box">
      <button @click="closeModal" type="button" class="close-modal">닫기</button>
    </div>
  </div>

  <div class="modal-popup modal-signin" v-if="tabMode === 'findPwdResult'">
    <button type="button" class="close-modal" @click="closeModal">
      <img src="@/assets/images/icon/ico_close_b.svg" alt=""/>
    </button>
    <div class="find-cont">
      <div id="find2" class="tab-cont">
        <h2>비밀번호 변경</h2>
        <form>
          <fieldset>
            <div class="field">
              <div class="input-box">
                <div class="pwd-wrap">
                  <input ref="accountPwd1Ref" :type="accountPwd1FieldType" v-model="accountPwd1" maxlength="16"
                         @input="validateAccountPwdForm"
                         placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                  <button @click="toggleAccountPwd1Visibility" :class="{ on: accountPwd1FieldType === 'text'}"
                          type="button" class="btn-pwd"></button>
                </div>
              </div>
              <p v-if="accountPwdError1" class="txt" style="color: red;">{{ accountPwdError1 }}</p>
            </div>
            <div class="field">
              <div class="input-box">
                <div class="pwd-wrap">
                  <input ref="accountPwd2Ref" :type="accountPwd2FieldType" v-model="accountPwd2" maxlength="16"
                         @input="validateAccountPwdForm"
                         placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                  <button @click="toggleAccountPwd2Visibility" :class="{ on: accountPwd2FieldType === 'text'}"
                          type="button" class="btn-pwd"></button>
                </div>
              </div>
              <p v-if="accountPwdError2" class="txt" style="color: red;">{{ accountPwdError2 }}</p>
              <p class="txt">비밀번호를 한번 더 입력해 주세요.</p>
            </div>
            <div class="button-box">
              <button @click="changeAccountPwd" type="button">확인</button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
  <!-- e::아이디 비번찾기 모달창 -->
</template>

<script setup>
import {onMounted, ref, toRefs, watch} from 'vue';
import OAuthLogin from "@/components/auth/OAuthLogin.vue";
import {useAuthStore} from "@/store/auth.module";
import router from "@/router";
import {apiClient} from "@/services/auth-header";
import {useNicePopup} from "@/composables/useNicePopup";

const authStore = useAuthStore();
const accountId = ref('');
const accountPwd = ref('');
const accountPwd1 = ref('');
const accountPwd2 = ref('');
const accountIdError = ref('');
const accountPwdError = ref('');
const pwdUpdateToken = ref('');
const tabMode = ref('findId');
const emit = defineEmits(['mode-change']);
const props = defineProps({
  mode: String
});
const validateForm = async () => {
  let isValid = true;
  if (!accountId.value) {
    accountIdError.value = '아이디를 입력해 주세요.';
    isValid = false;
  } else {
    accountIdError.value = '';
  }

  if (!accountPwd.value) {
    accountPwdError.value = '비밀번호를 입력해 주세요.';
    isValid = false;
  } else {
    accountPwdError.value = '';
  }
 return isValid;
}

const closeModal = () => {
  tabMode.value = "none";
  emit('mode-change', 'none');
};
const changeTabMode = (mode) => {
  emit('mode-change', "findInfo");
  tabMode.value = mode;
};
const handleLogin = async (event) => {
  event.preventDefault();
  try {
    await authStore.login({ accountId: accountId.value, accountPwd: accountPwd.value });
    emit('mode-change', 'none');
    if($(".h-cont").hasClass("active")){
      $(".h-cont").removeClass("active");
    }
    if($(".nav-icon").hasClass("open")){
      $(".nav-icon").removeClass("open");
    }
    await router.push("/");
  } catch (error) {
    console.error("error", error)
  }
};
const accountPwdFieldType = ref('password');
const toggleAccountPwdVisibility = () => {
  accountPwdFieldType.value = accountPwdFieldType.value === 'password' ? 'text' : 'password';
};

const { open } = useNicePopup();

const openNicePopup = async () => {
  if (tabMode.value === "pwd") {
    if (!accountId.value) {
      alert("아이디를 입력해 주세요.");
      return;
    }
    localStorage.setItem("accountId", accountId.value);
  }

  await open({
    type: tabMode.value,
    onPopupClose: () => {
      if (tabMode.value === "id") {
        const userId = localStorage.getItem("accountId");
        if (userId) {
          tabMode.value = "findIdResult";
          accountId.value = userId;
        }
      } else {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          tabMode.value = "findPwdResult";
          pwdUpdateToken.value = accessToken;
        }
      }
      localStorage.removeItem("accountId");
      localStorage.removeItem("accessToken");
    },
  });
};

const accountPwd1FieldType = ref('password');
const accountPwd2FieldType = ref('password');
const toggleAccountPwd1Visibility = () => {
  accountPwd1FieldType.value = accountPwd1FieldType.value === 'password' ? 'text' : 'password';
};
const toggleAccountPwd2Visibility = () => {
  accountPwd2FieldType.value = accountPwd2FieldType.value === 'password' ? 'text' : 'password';
};

const accountPwd1Ref = ref(null);
const accountPwd2Ref = ref(null);
const accountPwdError1 = ref('');
const accountPwdError2 = ref('');
const validateAccountPwdForm = async () => {
  // Password validation
  const accountPwdRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~]).{8,16}$/;
  if (!accountPwdRegex.test(accountPwd1.value)) {
    accountPwdError1.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    return false;
  } else {
    accountPwdError1.value = '';
  }

  if (accountPwd1.value !== accountPwd2.value) {
    accountPwdError2.value = '비밀번호가 일치하지 않습니다.';
    return false;
  } else {
    accountPwdError2.value = '';
  }
  return true;
}

const changeAccountPwd = async () => {
  const isValid = await validateAccountPwdForm();
  if (!isValid) {
    return;
  }
  try {
    await apiClient.put('/v1/auth/identity/password', {
      accountPwd: accountPwd1.value
    }, {
      headers: {
        Authorization: `Bearer ${pwdUpdateToken.value}` // Authorization 헤더에 JWT 토큰을 추가
      }
    });
    alert("비밀번호가 변경되었습니다.");
    pwdUpdateToken.value = '';
    location.reload();
  } catch (error) {
    console.error(error);
  }
}

onMounted(() => {
  emit('mode-change', 'none');
});
</script>