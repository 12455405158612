<template>
<footer>
    <div class="layout">
        <div class="f-logo">
            <figure><img src="@/assets/images/common/logo_w.svg" alt=""></figure>
        </div>
        <ul class="f-ul">
            <li>
                <address>
                    <ul class="f-info">
                        <li>
                            <span class="f-title">CEO</span>
                            <span class="f-txt">Ahrum Oh</span>
                        </li>
                        <li>
                            <span class="f-title">PIC</span>
                            <span class="f-txt">Jihyeon Kim</span>
                        </li>
                        <li>
                            <span class="f-title">OFFICE</span>
                            <span class="f-txt">3rd floor, 8, Hasin 1-gil, Gunsan-si, Jeonbuk, Republic of Korea <br> 4th floor, 40, Hyoryeong-ro, Seocho-gu, Seoul, Republic of Korea</span>
                        </li>
                    </ul>
                </address>
            </li>
            <li>
                <div class="f-content">
                    <span class="f-title f-tit-2">business<br>license</span>
                    <span class="f-txt">509-86-02834</span>
                    <span class="f-txt" @click="openPopup"><a href="#">Check business information</a></span>
                </div>
            </li>
            <li>
                <div class="f-content">
                    <span class="f-title f-tit-2">contact us<br>and work together</span>
                    <span class="f-txt">+82-(0)507-1395-5634</span>
                    <span class="f-txt"><a href="mailto:hello@article21.co.kr">hello@article21.co.kr</a></span>
                </div>
            </li>
            <li>
                <div class="f-content">
                    <div class="f-list">
                        <span class="f-title f-tit-2">1:1 Inquiry<br>& Terms of Use</span>
                        <div class="f-link">
                            <span class="f-txt"><a href="/">1:1 Inquiry</a></span>
                            <span class="f-txt"><a href="/terms">Terms of use</a></span>
                            <span class="f-txt"><a href="/privacy">Privacy Policy</a></span>
                        </div>
                    </div>
                    <span class="f-txt">+82-(0)10-2132-5634</span>
                    <span class="f-txt">work hours 10:00-18:00 Weekdays (Excluding 12:00-13:00)</span>
                </div>
            </li>
        </ul>
        <div class="f-ul-m">
            <div class="f-content">
                <span class="f-title">Contact us and work together</span>
                <span class="f-txt">+82-(0)507-1395-5634 | hello@article21.co.kr</span>
            </div>
            <div class="f-content">
                <span class="f-title">1:1 Inquiry & Terms of Use</span>
                <span class="f-txt">+82-(0)10-2132-5634</span>
                <span class="f-txt">work hours 10:00-18:00 Weekdays (Excluding 12:00-13:00)</span>
                <div class="f-link">
                    <span class="f-txt"><a href="/">1:1 Inquiry</a></span>
                    <span class="f-txt"><a href="/terms">Terms of use</a></span>
                    <span class="f-txt"><a href="/privacy">Privacy Policy</a></span>
                    <p class="f-detail" @click="toggleDetails"><span>MORE DETAILS</span><img src="@/assets/images/icon/ico_select_wh.svg" :class="{ 'rotate-180': isDetailsOpen }" alt=""></p>
                </div>
            </div>
            <div v-show="isDetailsOpen" class="f-content detail">
                <address>
                    <ul class="f-info">
                        <li>
                            <span class="f-title">CEO</span>
                            <span class="f-txt">Ahrum Oh</span>
                        </li>
                        <li>
                            <span class="f-title">PIC</span>
                            <span class="f-txt">Jihyeon Kim</span>
                        </li>
                        <li>
                            <span class="f-title">OFFICE</span>
                            <span class="f-txt">3rd floor, 8, Hasin 1-gil, Gunsan-si, Jeonbuk, Republic of Korea <br> 4th floor, 40, Hyoryeong-ro, Seocho-gu, Seoul, Republic of Korea</span>
                        </li>
                        <li>
                            <span class="f-title">business</span>
                            <span class="f-txt">509-86-02834 | 2023-전북군산-0606</span>
                        </li>
                        <li>
                            <span class="f-title">license</span>
                            <span class="f-txt" @click="openPopup"><a href="#">Check business information</a></span>
                        </li>
                    </ul>
                </address>
            </div>
        </div>
    </div>
</footer>
</template>

<script setup>
import { ref } from 'vue';

// 상태 변수 설정 (isDetailsOpen을 ref로 관리)
const isDetailsOpen = ref(false);

// 토글 기능
const toggleDetails = () => {
    console.log("test")
  isDetailsOpen.value = !isDetailsOpen.value;
};

const openPopup = () => {
  window.open("https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5098602834", "bizCommPop", "width=750, height=700;");
}
const openInisisPopup = () => {
  window.open('https://mark.inicis.com/mark/popup_v3.php?mid=article266','mark','scrollbars=no,resizable=no,width=565,height=683');
}
const openEscrowPopup = () => {
  window.open('https://mark.inicis.com/mark/escrow_popup_v3.php?mid=article266','mark','scrollbars=no,resizable=no,width=565,height=683');
}

</script>

<style scoped>
</style>
