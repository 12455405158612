<template>
  <h2>MY PAGE</h2>
  <dl>
    <dt v-if="isArtist || isArtistPending"><a href="#">ART & ARTISTS</a></dt>
    <dd v-if="isArtist || isArtistPending">
      <ul>
        <li @click="handleArtistMenuClick" :class="{ on: route.path.includes('profile') }">
          <router-link v-if="!isArtistPending" :to="{ name: 'MyProfile' }">프로필 관리</router-link>
          <a v-else href="#">프로필 관리</a>
        </li>
        <li @click="handleArtistMenuClick" :class="{ on: route.path.includes('artwork') }">
          <router-link v-if="!isArtistPending" :to="{ name: 'ProfileArtwork' }">아트워크 관리</router-link>
          <a v-else href="#">아트워크 관리</a>
        </li>
        <li @click="handleArtistMenuClick" :class="{ on: route.path.includes('portfolio') }">
          <router-link v-if="!isArtistPending" :to="{ name: 'Portfolio' }">포트폴리오 제작</router-link>
          <a v-else href="#">포트폴리오 제작</a>
        </li>
      </ul>
    </dd>
    <dt><a href="#">MY FAVORITE</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.includes('favoriteArtist') }">
          <router-link :to="{ name: 'FavoriteArtist' }">관심 아티스트</router-link>
        </li>
        <!-- todo: 추가 메뉴 라우터, 페이지 만들기-->
        <li><a href="my_favorite_artwork.html">관심 아트워크</a></li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('Scrap') }">
          <router-link :to="{ name: 'Scrap' }">스크랩 아티클</router-link>
        </li>
      </ul>
    </dd>
    <dt><a href="#">회원 정보 관리</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.includes('info') }">
          <router-link :to="{ name: 'MyPage' }">나의 정보</router-link>
        </li>
      </ul>
    </dd>
    <dt><a href="#">고객센터</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.includes('notice') }">
          <div class="link-wrapper" :class="{ new: unreadStore.unreadNoticesCount > 0 }">
            <router-link to="/support/notice">공지 사항</router-link>
          </div>
        </li>
        <!-- todo: 추가 메뉴 라우터, 페이지 만들기-->
        <li><a href="faq.html">FAQ</a></li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('inquiry') }">
          <div class="link-wrapper" :class="{ new: unreadStore.unreadAnsweredInquiriesCount > 0 }">
            <router-link to="/support/inquiry">1:1 문의</router-link>
          </div>
        </li>
      </ul>
    </dd>
  </dl>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/store/auth.module';
import { useUnreadStore } from '@/store/unread.module';

const authStore = useAuthStore();
const unreadStore = useUnreadStore();

const route = useRoute();
const user = authStore.status.user;

const isArtist = ref(user.roleType === "ARTIST");
const isArtistPending = ref(user.roleType.startsWith("ARTIST_PENDING"));

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};

const handleArtistMenuClick = () => {
  if (isArtistPending.value) {
    alert('현재 승인 대기 중이므로 해당 메뉴를 이용할 수 없습니다.');
    return;
  }

  toggleMenu();
};

// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

onMounted(() => {
  checkMobile();
});

</script>

<style scoped>
.link-wrapper {
  display: flex;
  /* 자식 요소들을 가로로 나란히 배치 */
  align-items: center;
  /* 세로 중앙 정렬 */
}

.link-wrapper.new::after {
  content: 'N';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #f00;
  color: #fff;
  font-size: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  /* 간격 추가 */
}
</style>