<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: ARTICLE -->
      <div class="article">
        <div class="layout">
          <div class="box">
            <ArticleSnb/>
            <div class="content-panel">

              <SearchBar
                  v-model="searchQuery"
                  placeholder="검색어를 입력해주세요"
                  @submit="onSearch"
              />

              <div class="list-type1">
                <ul>
                  <li v-for="(item, index) in events" :key="index">
                    <router-link :to="{ name: 'EventDetail', params: { uuid: item.uuid } }">
                      <div class="img" style="height: 407px;">
                        <img :src="item.imageUrl" alt=""/>
                      </div>
                      <div class="txt-box">
                        <h3 v-html="item.title"></h3>
                        <p class="date">
                          기간: {{ item.periodFrom }} ~ {{ item.periodTo }}
                        </p>
                        <p class="location">
                          장소: {{ item.location }}
                        </p>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>

              <Pagination
                  v-if="totalPages > 0"
                  :current-page="currentPage"
                  :total-pages="totalPages"
                  :visible-count="5"
                  @goToPage="goToPage"
              />

            </div>
          </div>
        </div>

      </div>
      <!-- e:: ARTICLE -->
</div>
  <!-- e::PAGE -->
</template>

<script setup>
import {apiClient} from "@/services/auth-header";
import {onMounted, ref} from "vue";
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import SearchBar from "@/components/SearchBar.vue";
import Pagination from "@/components/Pagination.vue"; // Pinia 스토어 경로

const loading = ref(true);
const error = ref(null);
const events = ref([]);

// 페이지 정보
const currentPage = ref(0);
const size = ref(12);
const totalPages = ref(0);

// 검색어
const searchQuery = ref("");

async function fetchEvents(page = 0) {
  loading.value = true;
  error.value = null;

  try {
    const response = await apiClient.get(`/v1/contents/articles/events?q=${searchQuery.value}&page=${page}&size=${size.value}`);
    events.value = response.data.content;     // 실제 데이터 목록
    currentPage.value = response.data.pageable.pageNumber;
    totalPages.value = response.data.totalPages;
  } catch (err) {
    error.value = err;
  } finally {
    loading.value = false;
  }
}

function goToPage(pageIndex) {
  fetchEvents(pageIndex);
}

function onSearch(query) {
  searchQuery.value = query;
  currentPage.value = 0;   // 검색 시 첫 페이지부터 다시 로딩
  fetchEvents(0);
}

onMounted(async () => {
  await fetchEvents();
});
</script>