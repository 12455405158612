<style scoped>
.info-icon {
  position: relative;
  display: inline-block;
  margin-left: 6px;     /* 라벨과 아이콘 사이 간격 */
  cursor: pointer;      /* 마우스 올렸을 때 포인터 */
  color: #666;          /* 아이콘 색상 */
  font-size: 14px;      /* 아이콘 크기 */
}

.info-icon .tooltip {
  display: none;
  position: absolute;
  //top: -500%;           /* 아이콘 위쪽으로 배치 (예시) */
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;         /* 툴팁 너비 */
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  line-height: 1.4;
  z-index: 9999;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.info-icon:hover .tooltip {
  display: block;
}
</style>

<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div v-if="user" class="content-panel">
              <div class="my-artartist">
                <div class="my-info">
                  <h2 class="section-title">나의 정보</h2>
                  <form>
                    <fieldset>
                      <div class="form-box">
                        <div class="field-row">
                          <div class="label">
                            <span>아이디</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-txt">{{ user.accountId }}</div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>이름</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-txt">{{ user.name }}</div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>생년월일</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-txt">{{ user.birthDate }}</div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>성별</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <select v-model="user.genderType">
                                <option value="MALE">남성</option>
                                <option value="FEMALE">여성</option>
                                <option value="NONE">선택안함</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>이메일</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <input v-model="user.email" type="text" @input="validateEmailForm">
                            </div>
                            <p v-if="emailError" class="txt" style="color: red;">{{ emailError }}</p>
                            <p class="checkbox-type2" style="margin-top: 10px">
                              <input type="checkbox" id="chk-email-agree2" v-model="user.agreeMarketingEmail">
                              <label for="chk-email-agree2">
                                (선택) 정보/이벤트 메일 수신에 동의합니다.
                              </label>
                            </p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>휴대폰 번호</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <div class="tel-box">
                                <div class="input-txt">{{ user.phoneNum }}</div>
                                <button type="button" class="btn" @click="openNicePopup">변경</button>
                              </div>
                            </div>
                            <p class="checkbox-type2">
                              <input type="checkbox" id="chk-sms-agree2" v-model="user.agreeMarketingSms">
                              <label for="chk-sms-agree2">
                                (선택) 정보/이벤트 SMS 수신에 동의합니다.
                              </label>
                            </p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>주소</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <button type="button" class="btn" @click="handleSearchAddress">주소 변경</button>
                              <div v-if="user.zoneCode" class="input-txt">({{ user.zoneCode }}) {{ user.address }} {{ user.buildingName }}</div>
                              <input v-if="user.zoneCode" v-model="user.addressDetail" type="text" placeholder="상세주소 입력">
                            </div>
                            <p v-if="addressError" class="txt" style="color: red;">{{ addressError }}</p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>
                                관심 분야<br>
                                (중복 선택 가능)
                            </span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <div v-if="isArtist" class="chk-group item3">
                                <span v-for="(interest, index) in ARTIST_INTERESTS" :key="index" class="checkbox-type2">
                                  <input type="checkbox" :id="'chk-ct' + index" :value="interest.value" v-model="user.artistInterests">
                                  <label :for="'chk-ct' + index">{{ interest.label }}</label>
                                  <input v-if="interest.value === 'ETC' && user.artistInterests.includes('ETC')"
                                         v-model="user.artistInterestEtc"
                                         type="text" class="etc" placeholder="직접 입력">
                                </span>
                              </div>
                              <div v-else class="chk-group item3">
                                <span v-for="(interest, index) in USER_INTERESTS" :key="index" class="checkbox-type2">
                                  <input type="checkbox" :id="'chk-ct' + index" :value="interest.value" v-model="user.userInterests">
                                  <label :for="'chk-ct' + index">{{ interest.label }}</label>
                                  <input v-if="interest.value === 'ETC' && user.userInterests.includes('ETC')"
                                         v-model="user.userInterestEtc"
                                         type="text" class="etc" placeholder="직접 입력">
                                </span>
                              </div>
                            </div>
                            <p v-if="interestError" class="txt" style="color: red;">{{ interestError }}</p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>선택약관 동의</span>
                          </div>
                          <div class="cmm-form">
                            <p class="checkbox-type2">
                              <input type="checkbox" id="chk-privacy-optional" v-model="user.privacyOptional">
                              <label for="chk-privacy-optional">
                                개인정보 수집 및 이용
                                <span class="info-icon">
                                  <i class="fa-solid fa-circle-info"></i>
                                  <div class="tooltip">
                                    선택적 개인정보의 수집 및 이용 목적<br>
                                    - 수집/이용 목적 : 주문/결제 시 상품 배송<br>
                                    - 수집 항목 : 구매자 정보, 상품 구매/취소/반품/교환/환불 정보, 수령인 정보<br>
                                    - 보유/이용 기간 : 회원 탈퇴 후 5일까지
                                  </div>
                                </span>
                              </label>
                            </p>
                          </div>
                        </div>
                        <div class="button-box">
                          <button @click="updateProfile" type="button">정보 수정</button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import common from "@/assets/js/common";
import {ARTIST_INTERESTS, USER_INTERESTS} from "@/common/common-enum";
import {useNicePopup} from "@/composables/useNicePopup";

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);

// 토글 메뉴 함수
const navIconRef = ref(null);
const sideRef = ref(null);
const toggleMenu = () => {
  navIconRef.value.classList.toggle('open');
  sideRef.value.classList.toggle('active');
  document.body.classList.toggle('on');
};

const user = ref(null);
const isArtist = ref(false);

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

onMounted(() => {
  loading.value = false;
});

const emailError = ref('');
const addressError = ref('');
const interestError = ref('');

const { open } = useNicePopup();

const validateForm = async () => {

  let isValid = true;

  // Email validation
  if (!user.value.email) {
    emailError.value = '이메일을 입력해 주세요.';
    isValid = false;
  } else if (!emailRegex.test(user.value.email)) {
    emailError.value = '유효한 이메일 주소를 입력해 주세요.';
    isValid = false;
  } else {
    emailError.value = '';
  }

  if (!user.value.address || !user.value.zoneCode || !user.value.addressDetail) {
    addressError.value = '주소를 입력해 주세요.';
    isValid = false;
  } else {
    addressError.value = '';
  }

  if (user.value.roleType === 'MEMBER') {
    if (!user.value.userInterests || user.value.userInterests.length === 0) {
      interestError.value = '관심분야를 1개 이상 선택해 주세요.';
      isValid = false;
    } else {
      interestError.value = '';
    }
  } else {
    if (!user.value.artistInterests || user.value.artistInterests.length === 0) {
      interestError.value = '관심분야를 1개 이상 선택해 주세요.';
      isValid = false;
    } else {
      interestError.value = '';
    }
  }

  return isValid;
};

// 데이터 로드 및 초기화
const loadProfile = async () => {
  try {
    const response = await apiClient.get('/v1/users/me');
    user.value = response.data;
    isArtist.value = response.data.roleType === "ARTIST" || response.data.roleType === "ARTIST_PENDING";
  } catch (error) {
    error.value = error;
  } finally {
    loading.value = false;
  }
};

const handleSearchAddress = async () => {
  try {
    const data = await common.searchAddress(); // 검색된 주소 데이터를 address에 저장
    user.value.address = data.address;
    user.value.zoneCode = data.zonecode;
    user.value.buildingName = data.buildingName;
  } catch (error) {
    console.error('주소 검색 중 에러가 발생했습니다.', error);
  }
};
const updateProfile = async () => {
  if (!await validateForm()) {
    return;
  }

  if (confirm("수정 하시겠습니까?")) {
    try {
      const response = await apiClient.put('/v1/users/me', user.value);
      if(response.status === 200){
        alert("수정 되었습니다.")
        await loadProfile();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};

const validateEmailForm = async () => {
  if (!user.value.email) {
    emailError.value = '이메일을 입력해 주세요.';
  } else if (!emailRegex.test(user.value.email)) {
    emailError.value = '유효한 이메일 주소를 입력해 주세요.';
  } else {
    emailError.value = '';
  }
}

const openNicePopup = async () => {
  await open({
    type: 'changePhone',
    onPopupClose: () => {
      const verify_data = JSON.parse(localStorage.getItem('verify_data'));
      localStorage.removeItem('verify_data');

      if (!verify_data || !user.value) {
        return;
      }

      if (user.value.name !== verify_data.name
          || user.value.birthDate !== verify_data.birthDate) {
        alert("변경하고자 하는 휴대폰 번호가 본인이 아닙니다.");
        return;
      }

      user.value.phoneNum = verify_data.phoneNum;
    },
  });
};

onMounted(() => {
  loadProfile();
});

</script>