import {createRouter, createWebHistory} from 'vue-router';
import Home from "@/views/Home.vue";
import {oauthRoutes} from "@/router/oauthRoutes";
import {userRoutes} from './userRoutes';
import {artistRoutes} from './artistRoutes';
import {articleRoutes} from './articleRoutes';
import Policy from "@/components/policy/Policy.vue";
import NotFound from "@/views/NotFound.vue";
import {supportRoutes} from "@/router/supportRoutes";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    ...userRoutes,
    ...oauthRoutes,
    ...artistRoutes,
    ...articleRoutes,
    ...supportRoutes,
    {
        path: '/privacy',
        name: 'PrivacyPolicy',
        component: Policy,
    },
    {
        path: '/terms',
        name: 'TermsPolicy',
        component: Policy,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    if(to.fullPath.includes("shop")){
        document.body.classList.add('bg-white');
    }else{
        document.body.classList.remove('bg-white');
    }
    // 로그인 여부를 확인
    const isAuthenticated = !!localStorage.getItem('user'); // 예시
    if (to.path === '/support/inquiry' && !isAuthenticated) {
        // 로그인 페이지로 리다이렉트
        alert("로그인 후 이용하실수 있습니다.");
        return;
    } else {
        next(); // 로그인이 되어 있거나 다른 경로일 경우 정상적으로 진행
    }
    next();
})
// router/index.js
// router.beforeEach((to, from, next) => {
    // const isAuthenticated = store.state.auth.status.loggedIn;
    // const isAdmin = store.state.auth.user && store.state.auth.user.roles[0] === 'ROLE_ADMIN';
    // if(isAuthenticated){
    // }
    //
    // if (to.path.includes('admin') && (!isAuthenticated || !isAdmin)) {
    //     next('/');
    // } else {
    //     next();
    // }
// });

export default router;
