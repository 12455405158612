import { defineStore } from "pinia";
import { apiClient } from "@/services/auth-header";
import { useAuthStore } from '@/store/auth.module';

export const useUnreadStore = defineStore("unread", {
  state: () => ({
    unreadAnsweredInquiriesCount: 0, // 미확인 답변된 문의글 수
    unreadNoticesCount: 0, // 미확인 공지 수
  }),
  actions: {
    // unreadAnsweredInquiriesCount 값 갱신
    setUnreadCount(count) {
      this.unreadAnsweredInquiriesCount = count;
    },

    // unreadNoticesCount 값 갱신
    setUnreadNoticesCount(count) {
      this.unreadNoticesCount = count;
    },

    // unreadAnsweredInquiriesCount 수 가져오기
    async fetchUnreadAnsweredInquiriesCount() {
      try {
        const response = await apiClient.get("/support/inquiries/unread-count"); 
        this.setUnreadCount(response.data); 
        // console.log("fetchUnreadAnsweredInquiriesCount", response.data)
      } catch (error) {
        console.error("Error fetching unread inquiries count:", error);
      }
    },

    // unreadNoticesCount 가져오기
    async fetchUnreadNoticesCount() {
      try {
        const response = await apiClient.get("/support/notices/unread-count"); 
        this.setUnreadNoticesCount(response.data); 
        // console.log("fetchUnreadNoticesCount", response.data);
      } catch (error) {
        console.error("Error fetching unread notices count:", error);
      }
    },

    // 모든 unread 상태 업데이트
    async fetchAllUnreadCounts() {
      // 로그인 했을때만 조회
      const authStore = useAuthStore();
      //console.log("fetchAllUnreadCounts", authStore.status.user)
      if(authStore.status.user == null) return;

      try {
        const [inquiriesResponse, noticesResponse] = await Promise.all([
          apiClient.get("/support/inquiries/unread-count"),
          apiClient.get("/support/notices/unread-count"),
        ]);
        this.setUnreadCount(inquiriesResponse.data);
        this.setUnreadNoticesCount(noticesResponse.data);
        // console.log("fetchAllUnreadCounts", [inquiriesResponse.data, noticesResponse.data]);
      } catch (error) {
        console.error("Error fetching all unread counts:", error);
      }
    },
  },
});
