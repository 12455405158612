<template>
  <div class="pagination" v-if="totalPages > 0">
    <!-- 맨 앞으로 이동 버튼 -->
    <button
        @click="emitGoToPage(0)"
        :disabled="currentPage === 0"
    >
      ««
    </button>

    <!-- 이전 페이지 이동 버튼 -->
    <button
        @click="emitGoToPage(currentPage - 1)"
        :disabled="currentPage === 0"
    >
      «
    </button>

    <!-- 페이지 번호 목록 -->
    <button
        v-for="pageIndex in pageNumbers"
        :key="pageIndex"
        :class="{ active: pageIndex === currentPage }"
        @click="emitGoToPage(pageIndex)"
    >
      {{ pageIndex + 1 }}
    </button>

    <!-- 다음 페이지 이동 버튼 -->
    <button
        @click="emitGoToPage(currentPage + 1)"
        :disabled="currentPage === totalPages - 1"
    >
      »
    </button>

    <!-- 맨 뒤로 이동 버튼 -->
    <button
        @click="emitGoToPage(totalPages - 1)"
        :disabled="currentPage === totalPages - 1"
    >
      »»
    </button>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";

/**
 * 부모로부터 받을 props 정의
 */
const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
  visibleCount: {
    type: Number,
    default: 5, // 한 번에 보여줄 페이지 수
  },
});

/**
 * 상위로 이벤트를 보낼 emit
 */
const emits = defineEmits(["goToPage"]);

/**
 * 페이지 번호 계산 로직
 */
const pageNumbers = computed(() => {
  const start = Math.max(0, props.currentPage - Math.floor(props.visibleCount / 2));
  const end = Math.min(props.totalPages - 1, start + props.visibleCount - 1);

  const adjustedStart = Math.max(0, end - props.visibleCount + 1);

  const pages = [];
  for (let i = adjustedStart; i <= end; i++) {
    pages.push(i);
  }
  return pages;
});

/**
 * 선택한 페이지를 부모 컴포넌트로 emit
 */
function emitGoToPage(pageIndex) {
  if (pageIndex < 0 || pageIndex >= props.totalPages) return;
  emits("goToPage", pageIndex);
}
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 16px;
}

.pagination button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 600;
  padding: 6px 10px;
  border-radius: 4px;
  color: #fff;

}

.pagination button.active {
  color: #FFD353;
}
</style>