<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: ARTICLE -->
      <div class="article">
        <div class="layout">
          <div class="box">
            <ArticleSnb/>
            <div class="content-panel">
              <div class="view-type1">
                <div class="img">
                  <img :src="event.imageUrl" alt="">
                </div>
                <div class="info-box">
                  <h3 v-html="event.title"/>
                  <dl>
                    <dt>기간</dt>
                    <dd>{{ getPeriod() }}</dd>
                    <template v-if="getTime()">
                      <dt>시간</dt>
                      <dd>{{ getTime() }}</dd>
                    </template>
                    <dt>장소</dt>
                    <dd>{{ event.location }}</dd>
                    <dt>문의</dt>
                    <dd>{{ event.contactPhoneNum }}</dd>
                    <dd v-if="event.contactEmail">{{ event.contactEmail }}</dd>
                    <template v-if="event.originLink">
                      <dt>링크</dt>
                      <dd>
                        <a :href="event.originLink" target="_blank" class="link">{{ event.originLink }}</a>
                      </dd>
                    </template>
                    <dd>
                      <p class="txt" style="white-space: pre-line;">
                        {{ event.content }}
                      </p>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: ARTICLE -->
</div>
  <!-- e::PAGE -->
</template>

<script setup>
import {apiClient} from "@/services/auth-header";
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";

const route = useRoute();
const articleUuid = ref(route.params.uuid);
const loading = ref(true);
const error = ref(null);
const event = ref(null);

const getPeriod = () => {
  let period = event.value.periodFrom + ' ~ ' + event.value.periodTo;
  if (event.value.periodMemo) {
    period += ' (' + event.value.periodMemo + ')';
  }
  return period;
}

const getTime = () => {
  let time = '';
  if (event.value.timeFrom) {
    time += event.value.timeFrom;
  }

  if (event.value.timeTo) {
    time += ' ~ ' + event.value.timeTo;
  }

  if (event.value.timeMemo) {
    time += ' (' + event.value.timeMemo + ')';
  }

  return time;
}

onMounted(async () => {
  try {
    const response = await apiClient.get(`/v1/contents/articles/events/${articleUuid.value}`);
    event.value = response.data;
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후에는 로딩 상태를 끝냅니다.
  }
});
</script>