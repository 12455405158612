<template>
  <!-- s::PAGE -->
  <div class="contents">
      <!-- s:: ART & ARTIST -->
      <div class="art-artist">
        <div class="list">
          <div class="layout">
            <div class="visual-slide">
              <div class="swiper visualSwiper">
                <div class="swiper-wrapper">
                  <div v-for="item in banners" class="swiper-slide">
                    <picture>
                      <!-- 모바일용 이미지 추후에 교체 해야 됩니다. -->
                      <source media="(max-width:768px)" :srcset="item.imageUrl">
                      <img :src="item.imageUrl" alt=""/>
                    </picture>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
                <h1>ART & ARTISTS</h1>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
              </div>
            </div>
            <div class="filter-box">
              <div class="search-box">
                <input type="text" v-model="searchQuery" placeholder="작가명 또는 작품명 검색" @keyup.enter="onSearch">
                <button type="button" id="search-button" @click="onSearch">
                  <img src="@/assets/images/icon/ico_search_b.svg" alt="">
                </button>
              </div>

              <!-- 정렬 방식 텍스트 -->
              <div class="sort-options">
                <span class="checkbox-type1">
                  <input type="checkbox" id="searchOnlyLike" v-model="searchOnlyLike" @change="toggleSearchOnlyLike">
                  <label for="searchOnlyLike">
                    관심 아티스트
                  </label>
                </span>
                <span
                    v-for="(option, index) in ARTIST_LIST_SORT_OPTIONS"
                    :key="index"
                    :class="{ selected: selectedSort.value === option.value }"
                    @click="setSortOption(option)"
                >
                  {{ option.label }}
                </span>
              </div>
            </div>

            <ul v-if="artists && artists.length > 0">
              <li v-for="(artist, index) in artists" :key="artist.uuid">
                <router-link :to="{ name: 'ArtistDetail', params: { uuid: artist.uuid } }">
                  <div class="txt">
                    <h3>{{ artist.nameEn }}</h3>
                    <p>{{ artist.nameKo }}</p>
                  </div>
                  <div class="img-box">
                    <figure>
                      <img :src="artist.imageUrl" alt=""/>
                    </figure>
                  </div>
                </router-link>
                <button type="button" class="btn-like" :class="{ on: artist.isLike }" @click="toggleLike($event, artist.uuid, index)">좋아요</button>
              </li>
            </ul>
            <ul v-else>
              <!-- 검색된 데이터가 없을 경우 -->
              <li class="nodata">
                검색된 작가 또는 작품명이 없습니다.
              </li>
            </ul>

            <Pagination
                v-if="totalPages > 0"
                :current-page="currentPage"
                :total-pages="totalPages"
                :visible-count="5"
                @goToPage="goToPage"
            />

          </div>
        </div>
      </div>
      <!-- e:: ART & ARTIST -->
    </div>
  <!-- e::PAGE -->
</template>
<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import Swiper from "swiper";
import {useAuthStore} from "@/store/auth.module";
import Pagination from "@/components/Pagination.vue";
import {ARTIST_LIST_SORT_OPTIONS} from "@/common/common-enum";

const authStore = useAuthStore();
const artists = ref(null);
const banners = ref([]);
const searchQuery = ref('');
const mainSwiper = ref();
const selectedSort = ref(ARTIST_LIST_SORT_OPTIONS[0]);
const searchOnlyLike = ref(false);

// 페이지 정보
const currentPage = ref(0);
const size = ref(24);
const totalPages = ref(0);

const setSortOption = (sort) => {
  if (selectedSort.value.value !== sort) {
    selectedSort.value = sort;
    fetchArtists(0); // 정렬 방식 변경 시 첫 페이지부터 다시 로딩
  }
};

const toggleSearchOnlyLike = () => {
  if (searchOnlyLike && !authStore.status.loggedIn) {
    searchOnlyLike.value = false;
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  fetchArtists(0); // 관심 아티스트 선택 시 첫 페이지부터 다시 로딩
}

function goToPage(pageIndex) {
  fetchArtists(pageIndex);
}

function onSearch() {
  currentPage.value = 0;   // 검색 시 첫 페이지부터 다시 로딩
  fetchArtists(0);
}

const toggleLike = async (event, artistUuid, index) => {
  if (!authStore.status.loggedIn) {
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }

  const isLiked = $(event.target).hasClass("on");
  const msg = !isLiked ? "관심 아티스트 등록" : "관심 아티스트 삭제";
  if (confirm(msg + " 하시겠습니까?")) {
    try {
      if (!isLiked) {
        await apiClient.post('/v1/users/me/likes', {
          type: "ARTIST",
          uuid: artistUuid,
        });
      } else {
        await apiClient.delete('/v1/users/me/likes', {
          data: {
            type: "ARTIST",
            uuid: artistUuid,
          },
        });
      }

      artists.value[index].isLike = !isLiked;
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }
  }
}

const fetchBanners = async () => {
  try {
    const response = await apiClient.get('/v1/contents/banners', {
      params: { type: 'ART_ARTIST' }
    });
    banners.value = response.data;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }
};

const fetchArtists = async (page = 0) => {
  try {
    const response = await apiClient.get(`/v1/contents/artists?q=${searchQuery.value}&like=${searchOnlyLike.value}&page=${page}&size=${size.value}&sort=${selectedSort.value.value}&direction=${selectedSort.value.direction}`);
    artists.value = response.data.content;
    currentPage.value = response.data.pageable.pageNumber;
    totalPages.value = response.data.totalPages;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }
};

const setSwiper = () => {
  mainSwiper.value = new Swiper('.visualSwiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: false,
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
    pagination: {
      el : ".swiper-pagination"
    },
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  }).init();
};

const init = () => {
  setSwiper();
  fetchBanners();
  fetchArtists();
};

onMounted(() => {
  init();
});
</script>

<style scoped>
.sort-options {
  display: flex;
  align-items: center;
  gap: 20px; /* 옵션 간의 간격 */
}

.sort-options span {
  cursor: pointer;
  font-size: 16px;
  color: #999; /* 기본 텍스트 색상 (회색) */
  transition: color 0.3s ease;
}

.sort-options span.selected {
  color: #ffc107; /* 선택된 텍스트 색상 (노란색) */
  font-weight: bold; /* 선택된 텍스트 강조 */
}
</style>