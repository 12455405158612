<template>
  <dl>
    <dt class="on"><a href="#">마이 페이지</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.includes('info') }">
          <router-link :to="{ name: 'MyPage' }">나의 정보</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('password') }">
          <router-link :to="{ name: 'PasswordUpdate' }">비밀번호 변경</router-link>
        </li>
      </ul>
    </dd>
    <dt v-if="isArtist || isArtistPending" class="on"><a href="#">ART & ARTISTS</a></dt>
    <dd v-if="isArtist || isArtistPending">
      <ul>
        <li @click="handleArtistMenuClick" :class="{ on: route.path.includes('profile') }">
          <router-link v-if="!isArtistPending" :to="{ name: 'MyProfile' }">프로필</router-link>
          <a v-else href="#">프로필</a>
        </li>
        <li @click="handleArtistMenuClick" :class="{ on: route.path.includes('artwork') }">
          <router-link v-if="!isArtistPending" :to="{ name: 'ProfileArtwork' }">아트 워크</router-link>
          <a v-else href="#">아트 워크</a>
        </li>
        <li @click="handleArtistMenuClick" :class="{ on: route.path.includes('portfolio') }">
          <router-link v-if="!isArtistPending" :to="{ name: 'Portfolio' }">포트폴리오</router-link>
          <a v-else href="#">포트폴리오</a>
        </li>
        <li @click="handleArtistMenuClick" :class="{ on: route.path.includes('favoriteArtist') }">
          <router-link v-if="!isArtistPending" :to="{ name: 'FavoriteArtist' }">관심 아티스트</router-link>
          <a v-else href="#">관심 아티스트</a>
        </li>
      </ul>
    </dd>
    <dt class="on"><a href="#">ARTICLE</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.includes('Scrap') }">
          <router-link :to="{ name: 'Scrap' }">스크랩 아티클</router-link>
        </li>
      </ul>
    </dd>
    <dt class="on"><a href="#">고객 센터</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.includes('notice') }">
          <router-link to="/support/notice">공지 사항  <i v-if="unreadStore.unreadNoticesCount > 0" class="fas fa-solid fa-circle" style="color: #f04747;"></i></router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('inquiry') }">
          <router-link to="/support/inquiry">1:1 문의  <i v-if="unreadStore.unreadAnsweredInquiriesCount > 0" class="fas fa-solid fa-circle" style="color: #f04747;"></i></router-link>
        </li>
      </ul>
    </dd>
  </dl>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/store/auth.module';
import { useUnreadStore } from '@/store/unread.module';

const authStore = useAuthStore();
const unreadStore = useUnreadStore();

const route = useRoute();
const user = authStore.status.user;

const isArtist = ref(user.roleType === "ARTIST");
const isArtistPending = ref(user.roleType === "ARTIST_PENDING");

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};

const handleArtistMenuClick = () => {
  if (isArtistPending.value) {
    alert('현재 승인 대기 중이므로 해당 메뉴를 이용할 수 없습니다.');
    return;
  }

  toggleMenu();
};

// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

onMounted(() => {
  checkMobile();
});

</script>
