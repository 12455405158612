<template>
  <div class="title">
    <span>{{ title }}</span>
  </div>
  <div class="col">
    <div class="row">
      <div class="field">
        <div class="inner-list">
          <div class="li" v-for="(item, index) in internalData" :key="index">
            <input type="text" v-model="item.value">
            <button v-if="index === 0" type="button" class="add-list" @click="addListItem(item.type, item.typeLabel)"><img src="@/assets/images/icon/ico_add2.svg"></button>
            <button v-else type="button" class="add-list" @click="removeItem(item.type, index)"><img src="@/assets/images/icon/ico_remove.svg"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive, toRefs} from 'vue'

const props = defineProps({
  title: String,
  data: Array,
});
const { title, data } = toRefs(props);

// props.data를 reactive 형태로 변환한 내부 데이터
const internalData = reactive(
    (props.data && props.data.length > 0) // props.data가 유효한 경우
        ? props.data.map((item) => ({
          value: item, // 기존 문자열을 객체 속성으로 변환
        }))
        : [{ value: '' }] // props.data가 없거나 빈 배열인 경우 기본값 추가
);

const addListItem = () => {
  internalData.push({ value: '' });
};
const removeItem = (type, index) => {
  internalData.splice(index, 1);

  // 데이터가 비어 있으면 기본값 추가
  if (internalData.length === 0) {
    internalData.push({ value: '' });
  }
};

const getData = () => {
  return Array.from(internalData)
  .filter(({ value }) => value) // 모든 필드가 채워진 항목만 필터링
  .map(({ value }) => value);  // value만 추출
};

defineExpose({
  getData
});
</script>

<style scoped>

</style>