import axios from 'axios';
import router from "@/router";
import {useAuthStore} from "@/store/auth.module";

const VUE_APP_API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
// Axios 인스턴스 생성
const apiClient = axios.create({
    baseURL: `${VUE_APP_API_BASE_URL}`,
    headers: {
        'Content-Type': 'application/json'
    }
});

// 요청 인터셉터 설정
apiClient.interceptors.request.use(config => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
        config.headers['Authorization'] = 'Bearer ' + user.accessToken;
    }
    return config;
}, error => {
    validateSession(error);
    return Promise.reject(error);
});

// 응답 인터셉터 설정
apiClient.interceptors.response.use(config => {
    return config;
}, error => {
    validateSession(error);
    return Promise.reject(error);
});


const validateSession = (error) => {
    const response = error.response;
    if (!response) {
        // 서버로부터 응답이 없을 때
        alert("서버로부터 응답이 없습니다.");
        return;
    }

    const data = response.data;
    const authStore = useAuthStore();

    if (response.status === 401) {
        alert(data.message);
        authStore.logout();
        router.push("/").then(value => location.reload());
    } else if (response.status === 403) {
        alert(data.message);
        router.push("/").then(value => location.reload());
    } else if (data && data.message) {
        alert(data.message);
    } else {
        alert("알 수 없는 오류가 발생했습니다.");
    }
};
export { apiClient };
