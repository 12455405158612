<template>
  <!-- s::PAGE -->
  <div class="contents">
      <!-- s:: ART & ARTIST -->
      <div v-if="artist" class="art-artist">
        <div class="view">
          <div class="layout">
            <div class="box">
              <div class="view-panel">
                <div class="artist-profile">
                  <div class="profile-box">
                    <div class="profile">
                      <figure>
                        <img :src="artist.imageUrl" alt="">
                      </figure>
                      <h2>{{ artist.nameEn }}</h2>
                      <h3>{{ artist.nameKo }}</h3>
                    </div>
                    <div class="career">
                      <div class="col1">
                        <h2>{{ artist.introduction }}</h2>
                      </div>
                      <div v-if="allInfo" class="col2">
                        <dl v-for="[key, value] in Object.entries(allInfo).slice(0,3)">
                          <dt>{{ key }}</dt>
                          <dd>
                            <ul>
                              <li v-for="item in value.slice(0,3)">
                                {{item}}
                              </li>
                            </ul>
                          </dd>
                        </dl>
                        <button v-if="allInfo" type="button" class="open-career" @click="openProfileModal">See More</button>
                      </div>
                    </div>
                  </div>
                  <div class="sort-options">
                    <span
                        :class="{ selected: !selectedArtWorksCategory }"
                        @click="setCategory(null)"
                    >
                      ALL
                    </span>
                    <span
                        v-for="(category, index) in artWorksCategories"
                        :key="index"
                        :class="{ selected: selectedArtWorksCategory && selectedArtWorksCategory.uuid === category.uuid }"
                        @click="setCategory(category)"
                    >
                      {{ category.name }}
                    </span>
                  </div>
                  <div class="artwork">
                    <ul>
                      <li v-for="(artWork, index) in artWorks" :key="index">
                        <div class="img">
                          <img :src="artWork.imageUrl" alt="{{ artWork.title }}" style="width: 452px;height: 452px;"/>
                        </div>
                        <div class="mask" @click="openArtWorkModal(artWork.uuid)">
                          <button type="button" class="open-artwork">See More</button>
                          <p>{{ artWork.title }}<br>
                            {{ artWork.material }}_{{ artWork.size }}<br>
                            {{ artWork.year }}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <button
                      v-if="currentPage < totalPages - 1"
                      class="see-more-btn"
                      @click="loadMoreArtWorks"
                  >
                    See More
                  </button>

                  <div v-if="artist.videoLink" class="video-section">
                    <h2 style="color: white">VIDEO CLIP</h2>
                    <div class="video-wrapper">
                      <!-- 유튜브 예시 -->
                      <iframe
                          :src="getEmbedVideoLink(artist.videoLink)"
                          width="100%"
                          height="450"
                          frameborder="0"
                          allowfullscreen
                      ></iframe>
                    </div>
                  </div>

                  <div v-if="artist.interviews && artist.interviews.length" class="interview-section">
                    <h2 style="color: white">INTERVIEW</h2>
                    <ul>
                      <li
                          v-for="(interview, index) in artist.interviews"
                          :key="index"
                          class="interview-item"
                      >
                        <a
                            v-if="interview.link"
                            :href="interview.link"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="title"
                        >
                          {{ interview.title ? interview.title : interview.link }}
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div v-if="isProfileModalOpen && allInfo" class="modal-career">
                    <button type="button" class="close-career" @click="closeProfileModal"><img src="@/assets/images/icon/ico_close_w.svg" alt=""></button>
                    <dl v-for="[key, value] in Object.entries(allInfo)">
                      <dt>{{ key }}</dt>
                      <dd>
                        <ul>
                          <li v-for="item in value">
                            {{ item }}
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </div>

                  <div v-if="isArtWorkModalOpen" class="modal-popup modal-artwork">
                    <div class="modal-con">
                      <div class="swiper-container slider main-swiper">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide" v-for="(artWork, index) in artWorks" :key="index" :data-id="artWork.uuid">
                            <div class="inner">
                              <div class="img zoom-area">
                                <img @click="toggleArtWorkEnlarge" :class="{ enlarged: isArtWorkEnlarged }" :src="artWork.imageUrl" :alt="artWork.title" style="height: 624px;"/>
                              </div>
                              <div class="txt-box">
                                <p>{{ artWork.title }}<br>
                                  {{ artWork.material }}_{{ artWork.size }}<br>
                                  {{ artWork.year }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="swiper-control">
                          <!-- Add Navigation if needed -->
                          <div class="swiper-button-prev"></div>
                          <div class="swiper-button-next"></div>
                        </div>
                      </div>
                      <div class="swiper-container slider-thumbnail">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide" v-for="(artWork, index) in artWorks" :key="index">
                            <img :src="artWork.imageUrl" alt="" style="max-height: 330px;"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="button" class="close-modal close-artwork" @click="closeArtWorkModal">
                      <img src="@/assets/images/icon/ico_close_b.svg" alt=""/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: ART & ARTIST -->
      <div v-else>
        <p>Loading...</p>
      </div>
  </div>
  <!-- e::PAGE -->
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import {apiClient} from '@/services/auth-header';
import {useRoute} from 'vue-router';
import {ARTIST_ACTIVITY_GENRES, ARTIST_EDUCATIONS, ARTIST_EDUCATIONS_STATUS} from "@/common/common-enum";
import Swiper from "swiper";

const route = useRoute();
const artistUuid = ref(route.params.uuid);
const artist = ref(null);
const artWorks = ref(null);
const artWorksCategories = ref(null);
const artWorkMainSwiper = ref(null);
const artWorkThumbnailSwiper = ref(null);

const selectedArtWorksCategory = ref(null);

// 페이지 정보
const currentPage = ref(0);
const size = ref(9);
const totalPages = ref(0);

const groupedExperiences = ref({})

const allInfo = ref({})

const isProfileModalOpen = ref(false);
const isArtWorkModalOpen = ref(false);
const isArtWorkEnlarged = ref(false);

const artistEducationsMap = ARTIST_EDUCATIONS.reduce((map, item) => {
  map[item.value] = item;
  return map;
}, {});

const artistEducationsStatusMap = ARTIST_EDUCATIONS_STATUS.reduce((map, item) => {
  map[item.value] = item;
  return map;
}, {});

const artistActivityGenresMap = ARTIST_ACTIVITY_GENRES.reduce((map, item) => {
  map[item.value] = item;
  return map;
}, {});

const openProfileModal = () => {
  isProfileModalOpen.value = true;
};

const closeProfileModal = () => {
  isProfileModalOpen.value = false;
};

const openArtWorkModal = (uuid) => {
  isArtWorkModalOpen.value = true;
  setTimeout(() => {
    if (artWorkMainSwiper.value) {
      const swiperInstance = artWorkMainSwiper.value;
      const slideElements = swiperInstance.slides; // Swiper의 슬라이드 요소들
      let slideIndex = -1;
      for (let i = 0; i < slideElements.length; i++) {
        if (slideElements[i].getAttribute('data-id') === uuid) {
          slideIndex = i;
          break;
        }
      }

      if (slideIndex !== -1) {
        swiperInstance.slideTo(slideIndex);
      } else {
        console.warn(`Slide with id ${uuid} not found`);
      }
    }
  }, 100);
};

const closeArtWorkModal = () => {
  isArtWorkModalOpen.value = false;
};

const toggleArtWorkEnlarge = () => {
  isArtWorkEnlarged.value = !isArtWorkEnlarged.value;
};

const initializeSwiper = () => {
  artWorkMainSwiper.value = new Swiper('.main-swiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: false,
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  });
  artWorkThumbnailSwiper.value = new Swiper('.slider-thumbnail', {
    slidesPerView: 6,
    spaceBetween: 20,
    loop: false,
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
    slideToClickedSlide: true,
    on: {
      click: function () {
        const clickedIndex = this.clickedIndex;
        if (artWorkMainSwiper.value) {
          artWorkMainSwiper.value.slideTo(clickedIndex); // 인덱스 이동
        }
      }
    },
    controller: {
      control: artWorkMainSwiper.value,
    },
  });
};

watch(isArtWorkModalOpen, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      initializeSwiper();
    }, 10);
  } else {
    if (artWorkMainSwiper.value) {
      artWorkMainSwiper.value.destroy(true, true);
      artWorkMainSwiper.value = null;
    }
    if (artWorkThumbnailSwiper.value) {
      artWorkThumbnailSwiper.value.destroy(true, true);
      artWorkThumbnailSwiper.value = null;
    }
  }
});

const setAllInfo = () => {
  if (artist.value.activityGenreTypes && artist.value.activityGenreTypes.length > 0) {
    allInfo.value['Genre'] = [artist.value.activityGenreTypes
    .map(item =>
        artistActivityGenresMap[item].value !== 'ETC'
            ? artistActivityGenresMap[item].label
            : artist.value.activityGenreEtc
    )
    .join(', ')
    ];
  }

  if (groupedExperiences.value['SOLO_EXHIBITION']) {
    allInfo.value['Solo Exhibition'] = groupedExperiences.value['SOLO_EXHIBITION'].map(
        (item) => `${item.year} ${item.title} | ${item.location}, ${item.region}`
    );
  }

  if (groupedExperiences.value['GROUP_EXHIBITION']) {
    allInfo.value['Group Exhibition'] = groupedExperiences.value['GROUP_EXHIBITION'].map(
        (item) => `${item.year} ${item.title} | ${item.location}, ${item.region}`
    );
  }

  if (artist.value.educations && artist.value.educations.length > 0) {
    allInfo.value['Education'] = artist.value.educations.map(item => {
      return `${artistEducationsMap[item.type].label} ${item.year} ${item.schoolName} | ${item.major} ${artistEducationsStatusMap[item.status].label}`;
    });
  }

  if (groupedExperiences.value['RESIDENCY']) {
    allInfo.value['Residency'] = groupedExperiences.value['RESIDENCY'].map(
        (item) => `${item.year} ${item.title} | ${item.location}, ${item.region}`
    );
  }

  if (groupedExperiences.value['WORKSHOP']) {
    allInfo.value['Workshop'] = groupedExperiences.value['WORKSHOP'].map(
        (item) => `${item.year} ${item.title} | ${item.location}, ${item.region}`
    );
  }

  if (groupedExperiences.value['GRANT']) {
    allInfo.value['Grant'] = groupedExperiences.value['GRANT'].map(
        (item) => `${item.year} ${item.title} | ${item.location}, ${item.region}`
    );
  }

  if (artist.value.domesticArea) {
    allInfo.value['Region'] = [`국내 ${artist.value.domesticArea}`];
  }

  if (artist.value.overseasArea) {
    allInfo.value['Region'] = [`해외 ${artist.value.overseasArea}`];
  }

  if (artist.value.awards && artist.value.awards.length > 0) {
    allInfo.value['Award'] = artist.value.awards;
  }

  if (artist.value.collections && artist.value.collections.length > 0) {
    allInfo.value['Collection'] = artist.value.collections;
  }
}

const getEmbedVideoLink = (videoLink) => {
  if (!videoLink) {
    return ''; // 값이 없으면 빈 문자열이나 null 반환
  }

  // 1) YouTube 일반 링크 (예: https://www.youtube.com/watch?v=abcdef)
  //    쿼리 파라미터 v=값 추출
  const youtubeWatchRegex = /(?:youtube\.com\/watch\?v=)([^&]+)(.*)/;
  const youtubeShortRegex = /(?:youtu\.be\/)([^?]+)(.*)/;

  // 2) Vimeo 링크 (예: https://vimeo.com/12345678)
  const vimeoRegex = /(?:vimeo\.com\/)([^?]+)/;

  // 1. YouTube - watch 링크
  let match = videoLink.match(youtubeWatchRegex);
  if (match && match[1]) {
    const videoId = match[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }

  // 2. YouTube - 단축 링크
  match = videoLink.match(youtubeShortRegex);
  if (match && match[1]) {
    const videoId = match[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }

  // 3. Vimeo 링크
  match = videoLink.match(vimeoRegex);
  if (match && match[1]) {
    const videoId = match[1];
    return `https://player.vimeo.com/video/${videoId}`;
  }

  // 4. 해당 사항 없으면 원본 링크 그대로 반환 or 처리
  return videoLink;
}

const fetchProfile = async () => {
  if (artistUuid.value) {
    try {
      const artistResponse = await apiClient.get(`/v1/contents/artists/${artistUuid.value}`);
      artist.value = artistResponse.data;

      groupedExperiences.value = artist.value.experiences.reduce((acc, experience) => {
        // 그룹화 기준: type
        const type = experience.type;

        // 해당 type이 처음이면 빈 배열 초기화
        if (!acc[type]) {
          acc[type] = [];
        }

        // 현재 experience를 해당 type에 추가
        acc[type].push(experience);

        return acc;
      }, {});

      setAllInfo();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
};

const fetchArtWorks = async (page = 0, append = false) => {
  try {
    if (artistUuid.value) {
      let api = `/v1/contents/artists/${artistUuid.value}/art-works?page=${page}&size=${size.value}`;
      if (selectedArtWorksCategory.value) {
        api += `&cUuid=${selectedArtWorksCategory.value.uuid}`;
      }
      const response = await apiClient.get(api);

      if (append) {
        artWorks.value = [...artWorks.value, ...response.data.content];
      } else {
        artWorks.value = response.data.content;
      }
      currentPage.value = response.data.pageable.pageNumber;
      totalPages.value = response.data.totalPages;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

const loadMoreArtWorks = () => {
  if (currentPage.value < totalPages.value - 1) {
    fetchArtWorks(currentPage.value + 1, true);
  }
};

const fetchArtWorksCategories = async () => {
  try {
    const response = await apiClient.get(`/v1/contents/artists/${artistUuid.value}/art-works/categories`);
    artWorksCategories.value = response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

const setCategory = (category) => {
  if (category) {
    if (!selectedArtWorksCategory.value || selectedArtWorksCategory.value.uuid !== category.uuid) {
      selectedArtWorksCategory.value = category;
      fetchArtWorks(0);
    }
  } else {
    if (selectedArtWorksCategory.value) {
      selectedArtWorksCategory.value = null;
      fetchArtWorks(0);
    }
  }
};

onMounted(() => {
  fetchProfile();
  fetchArtWorksCategories();
  fetchArtWorks();
});

</script>

<style scoped>
.sort-options {
  display: flex;
  align-items: center;
  gap: 20px; /* 옵션 간의 간격 */
}

.sort-options span {
  cursor: pointer;
  font-size: 16px;
  color: #999; /* 기본 텍스트 색상 (회색) */
  transition: color 0.3s ease;
}

.sort-options span.selected {
  color: #ffc107; /* 선택된 텍스트 색상 (노란색) */
  font-weight: bold; /* 선택된 텍스트 강조 */
}

.see-more-btn {
  margin: 20px auto;
  padding: 10px 20px;
  display: block;
  background-color: #ffc107;
  color: #000;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
.see-more-btn:hover {
  background-color: #daa106;
}

.video-section {
  margin-top: 40px;
}
.video-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
.video-wrapper {
  position: relative;
  width: 100%;
  max-width: 800px; /* 원하는 최대 너비 */
  margin: 0 auto;
}

.interview-section {
  margin-top: 40px;
}
.interview-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
.interview-section ul {
  list-style: none;
  padding: 0;
}
.interview-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
.interview-item .title {
  flex: 1;
  font-size: 18px;
  text-decoration: none;
  color: #fff;
}
.interview-item .title:hover {
  text-decoration: underline;
  color: #daa106;
}

</style>
