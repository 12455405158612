<template>
  verify
</template>
<script setup>
import {onMounted} from "vue";
import {apiClient} from "@/services/auth-header";
import {useRoute} from 'vue-router';

const route = useRoute();
const type = route.query.type;
const enc_data = route.query.enc_data;
const integrity_value = route.query.integrity_value;

const authentication = async () => {
  const verify = JSON.parse(localStorage.getItem('verify'));
  try {
    const response = await apiClient.post('/v1/auth/identity/result',{
      uniqueVal: verify.req_no,
      key: verify.key,
      iv: verify.iv,
      hmacKey: verify.hmac_key,
      encData: enc_data,
      integrity: integrity_value,
    });

    const data = {
      'uniqueVal': response.data.uniqueVal,
      'name': response.data.name,
      'birthDate': response.data.birthDate,
      'genderType': response.data.genderType,
      'phoneNum': response.data.phoneNum,
      'di': response.data.di,
    }
    localStorage.removeItem('verify');
    localStorage.setItem('verify_data', JSON.stringify(data));
    window.close();
  } catch (error) {
    console.error(error);
    window.close();
  }
}

const findAuthentication = async () => {
  const verify = JSON.parse(localStorage.getItem('verify'));

  let reqDto = {
    uniqueVal: verify.req_no,
    key: verify.key,
    iv: verify.iv,
    hmacKey: verify.hmac_key,
    encData: enc_data,
    integrity: integrity_value,
  };

  if (type === "pwd") {
    reqDto.accountId = localStorage.getItem('accountId');
  }

  try {
    const response = await apiClient.post('/v1/auth/identity/result/' + type, reqDto);

    if (type === "id") {
      localStorage.setItem('accountId', response.data.accountId);
    } else {
      localStorage.setItem('accessToken', response.data.accessToken);
    }
    localStorage.removeItem('verify');
    window.close();
  } catch (error) {
    console.error(error);
    window.close();
  }
}


onMounted(() => {
  if (type === "signup" || type === "changePhone") {
    authentication();
  } else {
    findAuthentication();
  }
});
</script>