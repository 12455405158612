import {apiClient} from "@/services/auth-header";

const API_URL = '/v1/auth';

class AuthService {
    login(reqDto) {
        return apiClient
            .post(API_URL + '/login', {
                accountId: reqDto.accountId,
                accountPwd: reqDto.accountPwd
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('menu');
    }
}

export default new AuthService();
