<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="my-artartist">
                <div class="profile">
                  <h2 class="section-title">프로필</h2>
                  <form>
                    <div class="register">
                      <div class="picture-box">
                        <div class="img-wrap">
                          <div class="filebox">
                            <label v-if="!previewImageFile.url" for="upload-img">이미지 업로드</label>
                            <input v-if="!previewImageFile.url" ref="fileInput" type="file" id="upload-img" @change="(event) => previewImage(event)" accept="image/*" />
                            <img v-if="previewImageFile.url" id="output" :src="previewImageFile.url" :alt="previewImageFile.name"/>
                          </div>
                        </div>
                        <div v-if="previewImageFile.url" class="filebox2" style="display: flex; flex-direction: row; align-items: center; justify-content: center; margin: 5px">
                          <label for="upload-img">이미지 변경</label>
                          <input type="file" id="upload-img" @change="(event) => previewImage(event)" accept="image/*" />
                        </div>
                        <p class="txt" style="color: white;">{{maxWidth}}x{{maxHeight}}px 이하 이미지를 등록해주세요.</p>
                      </div>
                      <div class="cont-box">
                        <ul>
                          <li>
                            <div class="title">
                              <span>작가명</span>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="sub-title">
                                  <span>국문 *</span>
                                </div>
                                <div class="field">
                                  <input type="text" v-model="artist.nameKo" style="height: 41px;">
                                </div>
                              </div>
                              <div class="row">
                                <div class="sub-title">
                                  <span>영문 *</span>
                                </div>
                                <div class="field">
                                  <input type="text" v-model="artist.nameEn" style="height: 41px;">
                                </div>
                              </div>
                            </div>
                          </li>
                          <ProfileEducation ref="educationRef" :data="artist.educations" />
                          <ProfileExperience ref="experienceRef" :data="artist.experiences"/>
                          <li>
                            <div class="title">
                              <span>활동 지역</span>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="sub-title">
                                  <span>국내</span>
                                </div>
                                <div class="field">
                                  <input type="text" v-model="artist.domesticArea">
                                </div>
                              </div>
                              <div class="row">
                                <div class="sub-title">
                                  <span>해외</span>
                                </div>
                                <div class="field">
                                  <input type="text" v-model="artist.overseasArea">
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="colspan">
                            <div class="title">
                              <span>활동 장르</span>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="field">
                                  <div class="inner-list">
                                    <div class="li">
                                      <div class="chk-group">
                                        <span v-for="genre in ARTIST_ACTIVITY_GENRES" :key="genre.value" class="checkbox-type1">
                                          <input type="checkbox" :id="'chk1-' + genre.value" :value="genre.value" v-model="artist.activityGenreTypes">
                                          <label :for="'chk1-' + genre.value">{{ genre.label }}</label>
                                        </span>
                                      </div>
                                      <input type="text" class="etc" v-model="artist.activityGenreEtc" v-show="artist.activityGenreTypes && artist.activityGenreTypes.includes('ETC')">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <!-- 수상 -->
                          <li class="colspan">
                            <ProfileInput ref="awardRef" title="수상 내역" :data="artist.awards" />
                          </li>
                          <!-- 작품소장 -->
                          <li class="colspan">
                            <ProfileInput ref="collectionRef" title="작품 소장 내역" :data="artist.collections" />
                          </li>
                          <li class="colspan">
                            <div class="title">
                              <span>아티스트 한 줄 소개</span>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="field">
                                  <div class="inner-list">
                                    <div class="li">
                                      <input type="text" v-model="artist.introduction">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="colspan">
                            <ProfileInput ref="interviewRef" title="인터뷰 링크" :data="artist.interviewLinks" />
                          </li>
                          <li class="colspan">
                            <div class="title">
                              <span>동영상 링크</span>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="field">
                                  <div class="inner-list">
                                    <div class="li">
                                        <input type="text" v-model="artist.videoLink">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div class="button-box">
                          <button type="button" @click="reload" style="margin-right: 10px;">취소하기</button>
                          <button type="button" @click="saveProfile">저장하기</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import 'swiper/swiper-bundle.css';
import {apiClient} from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import ProfileEducation from "@/components/ProfileEducation.vue";
import ProfileInput from "@/components/ProfileInput.vue";
import ProfileExperience from "@/components/ProfileExperience.vue";
import {ARTIST_ACTIVITY_GENRES} from "@/common/common-enum";

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const mode = ref("view");

// 토글 메뉴 함수
const navIconRef = ref(null);
const sideRef = ref(null);
const toggleMenu = () => {
  navIconRef.value.classList.toggle('open');
  sideRef.value.classList.toggle('active');
  document.body.classList.toggle('on');
};

const educationRef = ref(null);
const experienceRef = ref(null);
const awardRef = ref(null);
const collectionRef = ref(null);
const interviewRef = ref(null);

const artist = ref({});
const previewImageFile = ref(null);
const selectedImageFile = ref(null);

const reload = () => {
  window.location.reload();
};

// 컴포넌트가 마운트되었을 때 데이터 로드
onMounted(() => {
  loadData();
});

// 데이터 로드 및 초기화
const loadData = async () => {
  try {
    const response = await apiClient.get('/v1/artists/me');
    artist.value = response.data;
    previewImageFile.value = { url: artist.value.imageUrl };
    loading.value = false;
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};

const saveProfile = async () => {
  const isValid = await validateMandatory();
  if (!isValid) {
    loading.value = false;
    return;
  }

  if (confirm("저장 하시겠습니까?")) {
    loading.value = true; // 작업이 시작되면 로딩 상태를 시작합니다.
    const formData = new FormData();
    if (selectedImageFile.value) {
      formData.append('image', selectedImageFile.value);
    }

    artist.value.educations = educationRef.value.getData();
    artist.value.experiences = experienceRef.value.getFilteredData();
    artist.value.awards = awardRef.value.getData();
    artist.value.collections = collectionRef.value.getData();
    artist.value.interviewLinks = interviewRef.value.getData();
    formData.append('reqDto', new Blob([JSON.stringify(artist.value)], { type: 'application/json' }));
    try {
      const response = await apiClient.put('/v1/artists/me', formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if(response.status === 200){
        alert("저장 되었습니다.");
        location.reload();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};

const validateMandatory = async () => {
  if (!artist.value.nameKo && !artist.value.nameEn) {
    alert('작가명을 입력해 주세요.')
    return false;
  }

  if (!educationRef.value.validateData().isValid) {
    alert(educationRef.value.validateData().errorMessage);
    return false;
  }

  if (!experienceRef.value.validateData().isValid) {
    alert(experienceRef.value.validateData().errorMessage);
    return false;
  }

  if (!artist.value.domesticArea && !artist.value.overseasArea) {
    alert('활동지역을 입력해 주세요.');
    return false;
  }

  if (!artist.value.activityGenreTypes || artist.value.activityGenreTypes.length === 0) {
    alert('활동 장르를 선택해 주세요.');
    return false;
  }

  return true;
}

const fileInput = ref(null);
const maxWidth = 270;
const maxHeight = 377;
const previewImage = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  const url = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(new Error('파일 읽기 오류'));
    reader.readAsDataURL(file);
  });

  const img = new Image();
  const maxFileSize = 10 * 1024 * 1024; // 10MB
  img.onload = () => {
    if (img.width > maxWidth || img.height > maxHeight) {
      alert(`이미지 크기는 ${maxWidth}x${maxHeight} 픽셀을 초과할 수 없습니다.`);
      fileInput.value.value = ''; // 파일 입력 초기화
    } else if (file.size > maxFileSize) {
      alert('파일 크기는 10MB를 초과할 수 없습니다.');
      fileInput.value.value = ''; // 파일 입력 초기화
    } else {
      previewImageFile.value = {name: file.name, url, type: file.type};
      selectedImageFile.value = file;
    }
    URL.revokeObjectURL(url); // 메모리 해제를 위해 URL 객체 해제
  };
  img.src = url;
};
</script>