<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: ARTICLE -->
      <div class="article">
        <div class="layout">
          <div class="box">
            <ArticleSnb/>
            <div class="content-panel">
              <div class="view-type3">
                <!-- 상단 영역(좌측 타이틀/서브 타이틀/날짜, 우측 공유하기/Back 버튼) -->
                <div class="top-bar">
                  <div class="top-bar-left">
                    <h1 class="main-title">{{ special.title }}</h1>
                    <h2 class="sub-title">{{ special.subTitle }}</h2>
                    <p class="date">{{ formattedDate }}</p>
                  </div>
                  <div class="top-bar-right">
                    <!-- 공유하기 버튼 -->
                    <button class="share-button" @click="handleShare">
                      <i class="fa-solid fa-share-nodes"></i>
                    </button>
                    <!-- Back 버튼 -->
                    <button class="back-button" @click="handleBack">Back</button>
                  </div>
                </div>

                <figure v-if="special.mainImageUrl">
                  <img :src="special.mainImageUrl" alt=""/>
                </figure>
                <p v-if="special.mainContent" class="txt-box" style="white-space: pre-line;">
                  {{ special.mainContent }}
                </p>
                <template v-for="(content, index) in special.contents" :key="content.uuid">
                  <div :class="['box2', { reverse: index % 2 === 1 }]">
                    <div v-if="content.imageUrl" class="img">
                      <img :src="content.imageUrl" :alt="content.title"/>
                    </div>
                    <div class="txt">
                      <h3 v-if="content.title">{{ content.title }}</h3>
                      <p v-if="content.content" class="txt-box" style="white-space: pre-line;">
                        {{ content.content }}
                      </p>
                    </div>
                  </div>
                </template>

                <!-- 하단 white divider -->
                <div class="white-divider"></div>

                <!-- writer 영역 -->
                <div class="writer-section">
                  <div class="writer-image">
                    <img
                        v-if="special.writerImageUrl"
                        :src="special.writerImageUrl"
                        alt="writer image"
                    />
                  </div>
                  <div class="writer-info">
                    <p class="writer-header">writer</p>
                    <p class="writer">{{ special.writer }}</p>
                    <p class="writer-message">
                      {{ special.writerMessage }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: ARTICLE -->
    </div>
  <!-- e::PAGE -->
</template>

<script setup>
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import {computed, onMounted, ref} from "vue";
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";
import router from "@/router";

const route = useRoute();
const articleUuid = ref(route.params.uuid);
const loading = ref(true);
const error = ref(null);
const special = ref(null);

const formattedDate = computed(() => {
  if (!special.value || !special.value.createdAt) {
    return "";
  }
  const date = new Date(special.value.createdAt);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // 'YYYY-MM-DD HH:mm' 형식으로 반환
  return `${year}-${month}-${day} ${hours}:${minutes}`;
});

function handleShare() {
  // TODO 공유하기 기능 정의되면 구현 필요
}

function handleBack() {
  const { page, q } = route.query; // page와 title을 쿼리에서 가져옴
  router.push({ name: "Special", query: { page, q } });
}

onMounted(async () => {
  try {
    const response = await apiClient.get(`/v1/contents/articles/specials/${articleUuid.value}`);
    special.value = response.data;
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후에는 로딩 상태를 끝냅니다.
  }
});
</script>

<style scoped>
/* 상단 영역 */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.top-bar-left {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.main-title {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.sub-title {
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
}

.date {
  font-size: 14px;
  color: #fff;
}

.top-bar-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* 버튼 기본 스타일 */
.share-button,
.back-button {
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 4px;
  border: none;
}

.back-button {
  background-color: #ffda00; /* 노란색 Back 버튼 */
}

.share-button i {
  font-size: 24px; /* 아이콘 크기 */
  color: #fff;
}

/* 하단 white divider */
.white-divider {
  margin: 40px 0;
  width: 100%;
  height: 0.5px;
  background-color: #fff;
}

/* writer 영역 컨테이너 */
.writer-section {
  display: flex;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 60px;
  gap: 20px;
}

/* (1) writer 이미지를 위한 영역 */
.writer-image {
  width: 50%;
  max-width: 300px;
}
.writer-image img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* (2) writer 정보(이름, 메시지)를 위한 영역 */
.writer-info {
  flex: 0.5; /* 남은 공간을 차지하도록 */
  align-self: flex-start;
}

.writer-header {
  font-size: 14px;
  margin-bottom: 4px;
  color: #fff;
}

.writer {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #fff;
}

.writer-message {
  font-size: 14px;
  line-height: 1.6;
  color: #fff;
}
</style>