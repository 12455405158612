import Notice from "@/views/support/Notice.vue";
import Inquiry from "@/views/support/Inquiry.vue";
import NoticeDetail from "@/views/support/NoticeDetail.vue";
import InquiryDetail from "@/views/support/InquiryDetail.vue";
import InquiryCreate from "@/views/support/InquiryCreate.vue";

export const supportRoutes = [
    {
        path: '/support/notice',
        name: 'Notice',
        component: Notice,
    },
    {
        path: '/support/notice/:uuid',
        name: 'NoticeDetail',
        component: NoticeDetail,
        props: true
    },
    {
        path: '/support/inquiry',
        name: 'Inquiry',
        component: Inquiry,
    },
    {
        path: '/support/inquiry/:uuid',
        name: 'InquiryDetail',
        component: InquiryDetail,
        props: true
    },
    {
        path: '/support/inquiry/create',
        name: 'InquiryCreate',
        component: InquiryCreate,
    },

];
