<template>
    <HeaderSection :show-overlay="showOverlay" @mode-change="handleModeChange"/>
    <article id="pages">
      <router-view :key="$route.fullPath" @mode-change="handleModeChange"/>
    </article>
    <FloatingMenuBox @mode-change="handleModeChange"/>
    <FooterSection/>
    <Login :mode="mode" @mode-change="handleModeChange"/>
</template>
<script setup>
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import HeaderSection from '@/components/layout/Header.vue';
import FooterSection from '@/components/layout/Footer.vue';
import FloatingMenuBox from '@/components/layout/FloatingMenuBox.vue'; 
import Login from '@/components/auth/Login.vue';

const store = useStore();
const showOverlay = ref(false);
const currentUser = computed(() => store.state.auth.user);
const mode = ref('none');

const handleModeChange = (newMode) => {
  console.log("handleModeChange", newMode)
  mode.value = newMode;
   // todo: showOverlay -> header 에서 분리하기 (loading 포함 전역 객체로 관리)
  showOverlay.value = (newMode !== 'none');
  if(showOverlay.value){ // 기존 newMode === "artwork"
    document.body.classList.add('on');
  }else{
    document.body.classList.remove('on');
  }
};

const showArtistBoard = computed(() => {
  return currentUser.value && currentUser.value.roles && currentUser.value.roles.includes('ROLE_ARTIST');
});

const redirectIfWww = () => {
  const host = window.location.hostname;
  const url = window.location.href;

  if (host.startsWith('www.')) {
    const newUrl = url.replace('://www.', '://');
    window.location.replace(newUrl);
  }
};

onMounted(() => {
  redirectIfWww();
});
</script>

