<template>
  <li>
    <div class="title">
      <span>학력*</span>
    </div>
    <div class="col">
        <div class="row" v-for="(education, index) in ARTIST_EDUCATIONS" :key="index">
          <div class="sub-title">
            <span>{{ education.label }}</span>
          </div>
          <div class="field">
            <div class="inner-list">
              <div class="input-wrap">
                <select v-model="dataMap.get(education.value).year" >
                  <option value="">연도</option>
                  <option v-for="year in selectYearRange" :key="year" :value="year">{{ year }}</option>
                </select>
                <input type="text" v-model="dataMap.get(education.value).schoolName" maxlength="20" placeholder="학교명"/>
                <input type="text" v-model="dataMap.get(education.value).major" maxlength="20" placeholder="학과"/>
                <template v-if="education.value === 'ETC'">
                  <input type="text" v-model="dataMap.get(education.value).status" placeholder="졸업 여부">
                </template>
                <template v-else>
                  <select v-model="dataMap.get(education.value).status" >
                    <option value="">졸업 여부</option>
                    <option v-for="status in ARTIST_EDUCATIONS_STATUS" :value="status.value">{{ status.label }}</option>
                  </select>
                </template>
              </div>
            </div>
          </div>
        </div>
    </div>
  </li>

</template>

<script setup>
import {reactive, toRefs} from 'vue';
import {ARTIST_EDUCATIONS, ARTIST_EDUCATIONS_STATUS} from "@/common/common-enum";
import {getSelectYearRange} from "@/common/common-function";

const props = defineProps({
  data: Array,
});
const { data } = toRefs(props);

const selectYearRange = getSelectYearRange();

// ARTIST_EDUCATIONS를 기준으로 dataMap 초기화
const dataMap = reactive(new Map());
ARTIST_EDUCATIONS.forEach((education) => {
  const existingProfile = props.data?.find((profile) => profile.type === education.value);
  dataMap.set(education.value, existingProfile || {
    type: education.value,
    year: '',
    schoolName: '',
    major: '',
    status: ''
  });
});

const getData = () => {
  return Array.from(dataMap.values()).filter(({ year, schoolName, major, status }) => {
    return year && schoolName && major && status; // 모든 필드가 채워진 항목만 반환
  });
};

const validateData = () => {
  let isAnyFieldFilled = false; // 최소 하나의 항목이 입력되었는지 확인

  for (const [key, data] of dataMap.entries()) {
    const { year, schoolName, major, status } = data;

    // 하나라도 값이 입력된 경우
    if (year || schoolName || major || status) {
      isAnyFieldFilled = true; // 최소 하나의 값이 입력되었음을 표시

      // 모든 필드가 입력되었는지 검증
      if (!year || !schoolName || !major || !status) {
        return {
          isValid: false,
          errorMessage: `입력 중인 학력이 있습니다. 저장하시려면 모든 항목을 입력해주세요.\n사용하지 않을 학력 정보라면 입력된 항목을 삭제해 주세요.`,
        };
      }
    }
  }

  // 모든 값이 비어 있는 경우
  if (!isAnyFieldFilled) {
    return {
      isValid: false,
      errorMessage: "학력이 입력되지 않았습니다.\n대학, 대학원, 기타 중 하나 이상의 학력을 입력해주세요.",
    };
  }

  // Validation 성공
  return {
    isValid: true,
    errorMessage: null,
  };
};

defineExpose({
  getData,
  validateData
});

</script>