<style scoped>
.info-icon {
  position: relative;
  display: inline-block;
  margin-left: 6px;
  /* 라벨과 아이콘 사이 간격 */
  cursor: pointer;
  /* 마우스 올렸을 때 포인터 */
  color: #666;
  /* 아이콘 색상 */
  font-size: 14px;
  /* 아이콘 크기 */
}

.info-icon .tooltip {
  display: none;
  position: absolute;
  top: -500%;
  /* 아이콘 위쪽으로 배치 (예시) */
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  /* 툴팁 너비 */
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  line-height: 1.4;
  z-index: 9999;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.info-icon:hover .tooltip {
  display: block;
}
</style>

<template>
  <!-- s::PAGE -->

  <div v-if="loading" class="loading">
    <!-- <div class="spinner-wrap" style="display: block">
      <div class="temp-loading-box"> temp-loading-box 지워버렸네
        <i class="fas fa-solid fa-spinner fa-spin fa-5x" style="color: #ffffff"></i>
      </div>
    </div> -->
  </div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb />
            </div>
          </div>
          <div v-if="user" class="content-panel">
            <div class="my-artartist">
              <div class="my-info-wrap">
                <div class="my-info">
                  <div class="title-wrap">
                    <h2 class="section-title small">
                      {{
                        isApplyArtist
                          ? "아티스트 회원으로 전환 신청"
                          : "나의 정보"
                      }}
                      <span class="mb-grade">{{ userTypeText }}</span>
                    </h2>
                    <a v-if="!isApplyArtist && canApplyArtist" @click="handleArtistButtonClick" class="btn-change">아티스트
                      회원으로 전환 신청</a>
                  </div>
                  <form>
                    <fieldset>
                      <div class="form-box">
                        <div class="field-row">
                          <div class="label">
                            <span>아이디</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-txt" :class="{ column: isApplyArtist }">
                              <span id="userId">{{ user.accountId }}</span>
                              <span v-if="isApplyArtist">아이디는 아티스트 개별 페이지의 도메인으로 사용됩니다.</span>
                              <span v-if="isApplyArtist">예) https://article21.co.kr/{{ user.accountId }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>이름</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-txt">
                              <span id="userName">{{ user.name }}</span>
                              <div>
                                (<span id="userGender">{{
                                  user.genderType
                                }}</span>)
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>생년월일</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-txt">
                              <span id="userBirth">{{ user.birthDate }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>이메일</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box" style="
                                display: flex;
                                align-items: center;
                                gap: 9px;
                                flex-direction: row;
                              ">
                              <!-- todo:  퍼블 완료 후 마이페이지에 만들었는지 확인 후 tel-box 처럼 하나 만들기기 commmonui.css 에 추가-->
                              <input v-model="user.email" type="text" @input="validateEmail" ref="emailRef" />
                              <button v-if="
                                !btnSpinner && !isCheckEmailDuplicationSuccess
                              " type="button" class="btn" @click="checkEmailDuplication(user.email)"
                                :disabled="!user.email">
                                이메일 중복확인
                              </button>
                              <div v-else-if="btnSpinner">
                                <i class="fas fa-solid fa-spinner fa-spin fa-2x" style="color: #f7be17"></i>
                              </div>
                              <div v-else-if="isCheckEmailDuplicationSuccess">
                                <i class="fas fa-solid fa-check fa-2x" style="color: #f7be17"></i>
                              </div>
                            </div>
                            <p v-if="emailError" class="txt" style="color: red">
                              {{ emailError }}
                            </p>
                            <p v-if="isApplyArtist" class="checkbox-type2" :class="{ change: isApplyArtist }"
                              style="padding-top: 5px">
                              <input ref="agreeBusinessEmailRef" type="checkbox" id="chk-email-agree1"
                                v-model="user.agreeBusinessEmail" @change="validateAgreeBusinessEmail" />
                              <label for="chk-email-agree1">
                                (필수) 협업 제안 등 비즈니스 메일 수신에
                                동의합니다.
                              </label>
                            </p>
                            <p v-if="isApplyArtist && agreeBusinessEmailError" class="txt" style="color: red">
                              {{ agreeBusinessEmailError }}
                            </p>
                            <p class="checkbox-type2" :class="{ change: isApplyArtist }">
                              <input type="checkbox" id="chk-email-agree2" />
                              <label for="chk-email-agree2">
                                (선택) 정보/이벤트 메일 수신에 동의합니다.
                              </label>
                            </p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>휴대폰 번호</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <div class="tel-box">
                                <span class="txt">{{ user.phoneNum }}</span>
                                <button type="button" class="btn" @click="openNicePopup">
                                  변경
                                </button>
                              </div>
                            </div>
                            <p v-if="isApplyArtist" class="checkbox-type2" :class="{ change: isApplyArtist }"
                              style="padding-top: 5px">
                              <input ref="agreeBusinessSmsRef" type="checkbox" id="chk-sms-agree1"
                                v-model="user.agreeBusinessSms" @change="validateAgreeBusinessSms" />
                              <label for="chk-sms-agree1">
                                (필수) 협업 제안 등 비즈니스 전화/SMS 수신에
                                동의합니다.
                              </label>
                            </p>
                            <p v-if="isApplyArtist && agreeBusinessSmsError" class="txt" style="color: red">
                              {{ agreeBusinessSmsError }}
                            </p>
                            <p class="checkbox-type2" :class="{ change: isApplyArtist }">
                              <input type="checkbox" id="chk-sms-agree2" />
                              <label for="chk-sms-agree2">
                                (선택) 정보/이벤트 SMS 수신에 동의합니다.
                              </label>
                            </p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>주소</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <button type="button" class="btn" @click="handleSearchAddress">
                                주소 변경
                              </button>
                              <div v-if="user.zoneCode" class="input-txt">
                                ({{ user.zoneCode }}) {{ user.address }}
                                {{ user.buildingName }}
                              </div>
                              <input v-if="user.zoneCode" v-model="user.addressDetail" type="text" placeholder="상세주소 입력"
                                @input="validateAddress" ref="addressDetailRef" />
                            </div>
                            <p v-if="addressError" class="txt" style="color: red">
                              {{ addressError }}
                            </p>
                          </div>
                        </div>
                        <div class="field-row" ref="interestRef">
                          <div class="label">
                            <span>
                              관심 분야<br />
                              (중복 선택 가능)
                            </span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <div v-if="isArtist || isApplyArtist" class="chk-group item3">
                                <span v-for="(interest, index) in ARTIST_INTERESTS" :key="index" class="checkbox-type2"
                                  :class="{ change: isApplyArtist }">
                                  <input type="checkbox" :id="'chk-ct' + index" :value="interest.value"
                                    v-model="user.artistInterests" @change="validateInterests" />
                                  <label :for="'chk-ct' + index">{{
                                    interest.label
                                  }}</label>
                                  <input v-if="
                                    interest.value === 'ETC' &&
                                    user.artistInterests.includes('ETC')
                                  " v-model="user.artistInterestEtc" type="text" class="chk-etc-input" id="extraText"
                                    placeholder="직접 입력" />
                                </span>
                              </div>
                              <div v-else class="chk-group item3">
                                <span v-for="(interest, index) in USER_INTERESTS" :key="index" class="checkbox-type2">
                                  <input type="checkbox" :id="'chk-ct' + index" :value="interest.value"
                                    v-model="user.userInterests" @change="validateInterests" />
                                  <label :for="'chk-ct' + index">{{
                                    interest.label
                                  }}</label>
                                  <input v-if="
                                    interest.value === 'ETC' &&
                                    user.userInterests.includes('ETC')
                                  " v-model="user.userInterestEtc" type="text" class="chk-etc-input"
                                    placeholder="직접 입력" />
                                </span>
                              </div>
                            </div>
                            <p v-if="interestError" class="txt" style="color: red">
                              {{ interestError }}
                            </p>
                          </div>
                        </div>

                        <div v-if="isApplyArtist" class="field-row" ref="authFilesRef">
                          <div class="label">
                            <span>
                              아티스트<br>
                              활동 자료
                            </span>
                          </div>
                          <div class="cmm-form">
                            <div class="file-upload-container">
                              <div class="file-buttons">
                                <div class="file-upload-box">
                                  <label class="file-button" for="certificate1">
                                    재학/졸업증명서
                                    <input type="file" id="certificate1" class="file-input" @change="handleFileChange"
                                      accept=".png, .jpg, .jpeg, .pdf" multiple />
                                  </label>
                                </div>
                                <div class="file-upload-box">
                                  <label class="file-button" for="certificate2">
                                    예술인활동증명
                                    <input type="file" id="certificate2" class="file-input" @change="handleFileChange"
                                      accept=".png, .jpg, .jpeg, .pdf" multiple />
                                  </label>
                                </div>
                                <div class="file-upload-box">
                                  <label class="file-button" for="certificate3">
                                    기타(전시 홍보물 등)
                                    <input type="file" id="certificate3" class="file-input" @change="handleFileChange"
                                      accept=".png, .jpg, .jpeg, .pdf" multiple />
                                  </label>
                                </div>
                              </div>
                              <div class="file-names">
                                <template v-for="(file, index) in authFiles" :key="index">
                                  <div class="file-name" :class="'file-item-' + index">
                                    <span>{{ file.name }}</span>
                                    <button @click="deleteFile(index)" type="button">
                                      <img src="@/assets/images/icon/ico_close_gray.svg"
                                        style="width: 18px; padding-left: 5px" alt="" />
                                    </button>
                                  </div>
                                </template>
                              </div>

                              <div class="file-exp">
                                <p>아티스트 활동을 확인할 수 있는 자료를 1개 이상 업로드해 주세요. <br>
                                  (관련 학교 재학/졸업증명서, 예술인활동증명, 개인전/그룹전 홍보물 등 PDF, JPG, PNG 형식으로)</p>
                              </div>
                              <p v-if="authFilesError" class="txt" style="color: red">
                                {{ authFilesError }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="button-box">
                          <button v-if="isApplyArtist" @click="applyArtist" type="button" class="column">
                            전환 신청
                            <span>활동 자료 검토 후 승인</span>
                          </button>
                          <button v-else @click="updateProfile" type="button">
                            정보 수정
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
                <div v-if="!isApplyArtist" class="my-info">
                  <div class="title-wrap">
                    <h2 class="section-title small">비밀번호 변경</h2>
                  </div>
                  <form>
                    <fieldset>
                      <div class="form-box">
                        <div class="field-row">
                          <div class="label">
                            <span>현재 비밀번호</span>
                          </div>
                          <div class="cmm-form password">
                            <div class="input-box input-box-password">
                              <input v-model="currentPassword" @input="validateCurrentPasswordForm"
                                :type="passwordFieldType" placeholder="현재 비밀번호를 입력하세요." />
                              <button @click="togglePasswordVisibility" :class="{ on: passwordFieldType === 'text' }"
                                type="button" class="btn-pwd"></button>
                              <!-- todo: error 메세지 레이아웃 퍼블 맡기지 않아서 추후 수정-->
                              <p v-if="currentPasswordError" class="txt" style="color: red">
                                {{ currentPasswordError }}
                              </p>
                            </div>
                            <div class="btn-wrap"> <!-- 배포 후 다른 화면에서 gap 확인 style="display: flex; gap: 7px"-->
                              <button v-if="
                                !btnSpinner && !isCheckCurrentPasswordSuccess
                              " type="button" class="btn" @click="checkCurrentPassword(currentPassword)"
                                :disabled="!currentPassword">
                                비밀번호 확인
                              </button>
                              <div v-else-if="btnSpinner">
                                <i class="fas fa-solid fa-spinner fa-spin fa-2x" style="color: #f7be17"></i>
                              </div>
                              <div v-else-if="isCheckCurrentPasswordSuccess">
                                <i class="fas fa-solid fa-check fa-2x" style="color: #f7be17"></i>
                              </div>
                              <button type="button" class="btn" @click="findPassword">
                                비밀번호 찾기
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>신규 비밀번호</span>
                          </div>
                          <div class="cmm-form password">
                            <div class="input-box input-box-password new">
                              <input v-model="password1" :type="password1FieldType" @input="validateAccountPwdForm"
                                placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                              <button @click="togglePassword1Visibility" :class="{ on: password1FieldType === 'text' }"
                                type="button" class="btn-pwd"></button>
                            </div>
                            <p v-if="passwordError1" class="txt" style="color: red;">{{ passwordError1 }}</p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>비밀번호 확인</span>
                          </div>
                          <div class="cmm-form password">
                            <div class="input-box input-box-password new">
                              <input v-model="password2" :type="password2FieldType" @input="validateAccountPwdForm"
                                placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                              <button @click="togglePassword2Visibility" :class="{ on: password2FieldType === 'text' }"
                                type="button" class="btn-pwd"></button>
                            </div>
                            <p v-if="passwordError2" class="txt" style="color: red;">{{ passwordError2 }}</p>
                          </div>
                        </div>
                        <div class="button-box">
                          <button @click="updatePassword" type="button">비밀번호 변경</button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
                <div v-if="!isApplyArtist" class="my-info">
                  <div class="btn-wrap">
                    <button type="button" class="member-cancel" @click="openModal">
                      회원 탈퇴
                    </button>
                  </div>
                  <div v-if="isModalVisible" class="modal-wrap-3" style="display: flex">
                    <div class="modal-inner">
                      <button type="button" class="close-modal" @click="closeModal">
                        <img src="@/assets/images/icon/ico_close_w.svg" alt="" />
                      </button>
                      <div class="modal-content">
                        <h4>회원 탈퇴 하시겠습니까?</h4>
                        <p>
                          저희는 귀하의 경험을 소중히 여기며,<br />언제든지
                          돌아오실 수 있습니다.
                        </p>
                      </div>
                      <div class="button-box">
                        <button type="button" @click="handleWithdrawal">
                          탈퇴하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->

  <!-- <button
    ref="navIconRef"
    @click="toggleMenu"
    type="button"
    class="mypage-navi"
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button> -->
</template>


<script setup>
import { onMounted, ref, computed, nextTick, watch } from "vue";
import { apiClient } from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import common from "@/assets/js/common";
import { ARTIST_INTERESTS, USER_INTERESTS } from "@/common/common-enum";
import { useNicePopup } from "@/composables/useNicePopup";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

// 상태 변수 선언
const { open } = useNicePopup();
const loading = ref(true);
const error = ref(null);

const btnSpinner = ref(false);
const isCheckEmailDuplicationSuccess = ref(true);

// 토글 메뉴 함수
const navIconRef = ref(null);
const sideRef = ref(null);
const toggleMenu = () => {
  navIconRef.value.classList.toggle("open");
  sideRef.value.classList.toggle("active");
  document.body.classList.toggle("on");
};

const user = ref(null);
const isArtist = ref(false);
const isApplyArtist = ref(false); // 특정 영역 표시 여부
const canApplyArtist = ref(false);
const authFiles = ref([]);

const emailError = ref("");
const addressError = ref("");
const interestError = ref("");
const agreeBusinessSmsError = ref("");
const agreeBusinessEmailError = ref("");
const authFilesError = ref("");

const emailRef = ref(null);
const agreeBusinessEmailRef = ref(null);
const agreeBusinessSmsRef = ref(null);
const addressDetailRef = ref(null);
const interestRef = ref(null);
const authFilesRef = ref(null);

const userTypeText = computed(() => {
  if (isArtist.value) {
    // const roleType = user.value.roleType === 'ARTIST_PENDING' ? '(승인 대기)' : '';
    return `아티스트 회원(${user.value.roleType})`;
  } else if (isApplyArtist.value) {
    return "일반회원 > 아티스트 회원";
  }
  return "일반회원";
});

// 아티스트 회원으로 전환 버튼 클릭 시 호출되는 함수
const handleArtistButtonClick = () => {
  router.push({ name: "ApplyArtistPage" });
};

const handleFileChange = (event) => {
  const files = Array.from(event.target.files);
  const validExtensions = ["png", "jpg", "jpeg", "pdf"];

  // 유효하지 않은 파일 확인
  const invalidFiles = files.filter((file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return !validExtensions.includes(fileExtension);
  });

  if (invalidFiles.length > 0) {
    alert("PNG, JPG, JPEG, PDF 파일만 업로드 가능합니다.");
    event.target.value = ""; // 입력 값 초기화
    return;
  }

  const maxFiles = 8; // 최대 파일 개수
  const MB = 1024 ** 2;
  const MAX_TOTAL_SIZE_MB = 100;
  const maxTotalSize = MAX_TOTAL_SIZE_MB * MB; // 최대 업로드 크기 (100MB)

  // 현재 업로드된 파일의 개수와 크기 합산 계산
  const currentFileCount = authFiles.value.length;
  const currentTotalSize = authFiles.value.reduce(
    (sum, file) => sum + file.size,
    0
  );

  // 새 파일의 개수와 크기 합산 계산
  const newFileCount = files.length;
  const newTotalSize = files.reduce((sum, file) => sum + file.size, 0);

  // 파일 개수 초과 확인
  if (currentFileCount + newFileCount > maxFiles) {
    alert(`최대 ${maxFiles}개의 파일만 등록할 수 있습니다.`);
    return;
  }

  // 파일 크기 초과 확인
  if (currentTotalSize + newTotalSize > maxTotalSize) {
    alert(
      `업로드된 파일의 총 크기는 ${MAX_TOTAL_SIZE_MB}MB를 초과할 수 없습니다.`
    );
    return;
  }

  // 유효한 파일 추가
  files.forEach((file) => authFiles.value.push(file));
  validateAuthFiles();
};

const deleteFile = (index) => {
  if (confirm("삭제 하시겠습니까?")) {
    authFiles.value.splice(index, 1);
    validateAuthFiles();
  }
};

// 이메일 검증 함수
const validateEmail = async () => {
  isCheckEmailDuplicationSuccess.value = false;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (!user.value.email) {
    emailError.value = "이메일을 입력해 주세요.";
    return emailRef;
  }

  if (!emailRegex.test(user.value.email)) {
    emailError.value = "유효한 이메일 주소를 입력해 주세요.";
    return emailRef;
  }

  emailError.value = "";
  return true;
};

const validateEmailDuplication = () => {
  if (!isCheckEmailDuplicationSuccess.value) {
    emailError.value = "이메일 중복 확인해 주세요.";
    return emailRef;
  }
  return true;
};

const checkEmailDuplication = async (email) => {
  if ((await validateEmail()) !== true) return;

  btnSpinner.value = true;
  try {
    const response = await apiClient.get("/v1/auth/is-duplicate-email", {
      params: { email: email },
    });

    if (response.data) {
      emailError.value = "이미 사용 중인 이메일입니다.";
      return emailRef;
    }

    emailError.value = "";
    isCheckEmailDuplicationSuccess.value = true;
  } catch (error) {
    console.error("이메일 중복 체크에 실패했습니다.", error);
  } finally {
    btnSpinner.value = false;
  }
};

// 비즈니스 이메일 동의 검증 함수
const validateAgreeBusinessEmail = () => {
  if (user.value.roleType === "MEMBER") return true;

  if (!user.value.agreeBusinessEmail) {
    agreeBusinessEmailError.value = "비즈니스 이메일 수신에 동의해야 합니다.";
    return agreeBusinessEmailRef;
  }
  agreeBusinessEmailError.value = "";
  return true;
};

// 비즈니스 문자 동의 검증 함수
const validateAgreeBusinessSms = () => {
  if (user.value.roleType === "MEMBER") return true;

  if (!user.value.agreeBusinessSms) {
    agreeBusinessSmsError.value = "비즈니스 SMS 수신에 동의해야 합니다.";
    return agreeBusinessSmsRef;
  }
  agreeBusinessSmsError.value = "";
  return true;
};

// 주소 검증 함수
const validateAddress = () => {
  if (
    !user.value.address ||
    !user.value.zoneCode ||
    !user.value.addressDetail
  ) {
    addressError.value = "주소를 입력해 주세요.";
    return addressDetailRef;
  }
  addressError.value = "";
  return true;
};

// 관심 분야 검증 함수
const validateInterests = () => {
  const interest =
    user.value.roleType === "MEMBER"
      ? user.value.userInterests
      : user.value.artistInterests;
  if (!interest || interest.length === 0) {
    interestError.value = "관심분야를 1개 이상 선택해 주세요.";
    return interestRef;
  }

  interestError.value = "";
  return true;
};

const validateAuthFiles = () => {
  if (user.value.roleType === "MEMBER") return true;

  if (!authFiles.value || authFiles.value.length === 0) {
    authFilesError.value =
      "아티스트 활동을 확인할 수 있는 자료를 업로드해 주세요.";
    return authFilesRef;
  }
  authFilesError.value = "";
  return true;
};

const validateForm = async () => {
  // 화면 위->아래 순서대로 검증 함수들
  const validationFunctions = [
    ...(isCheckEmailDuplicationSuccess.value
      ? []
      : [validateEmail, validateEmailDuplication]),
    ...(isApplyArtist.value
      ? [validateAgreeBusinessEmail, validateAgreeBusinessSms]
      : []),
    validateAddress,
    validateInterests,
    ...(isApplyArtist.value ? [validateAuthFiles] : []),
  ];

  let firstErrorField = null;
  const isValid = await validationFunctions.reduce(
    async (accPromise, validateFn) => {
      const acc = await accPromise; // 이전 단계의 결과 기다리기
      const errorField = await validateFn(); // 비동기 검증 함수 호출하고 결과 받기
      if (errorField !== true && !firstErrorField) {
        firstErrorField = errorField; // 첫 번째 에러 필드를 설정
      }
      return acc && errorField === true; // 하나라도 에러가 있으면 isValid가 false
    },
    Promise.resolve(true)
  );

  if (!isValid && firstErrorField) {
    nextTick(() => {
      firstErrorField.value?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      // focus() 가 바로 위로 가서 500ms 후 적용
      setTimeout(() => {
        firstErrorField.value?.focus();
      }, 500);
    });
  }

  return isValid;
};

const handleSearchAddress = async () => {
  try {
    const data = await common.searchAddress(); // 검색된 주소 데이터를 address에 저장
    user.value.address = data.address;
    user.value.zoneCode = data.zonecode;
    user.value.buildingName = data.buildingName;
  } catch (error) {
    console.error("주소 검색 중 에러가 발생했습니다.", error);
  }
};

const updateProfile = async () => {
  if (!(await validateForm())) {
    return;
  }

  if (confirm("수정 하시겠습니까?")) {
    try {
      loading.value = true;
      const response = await apiClient.put("/v1/users/me", user.value);
      if (response.status === 200) {
        alert("수정 되었습니다.");
        await loadProfile();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};

const applyArtist = async () => {
  if (!(await validateForm())) {
    return;
  }

  if (confirm("전환 신청 하시겠습니까?")) {
    try {
      loading.value = true;
      const form = new FormData();
      authFiles.value.forEach((file) => form.append("files", file));
      form.append(
        "reqDto",
        new Blob([JSON.stringify(user.value)], { type: "application/json" })
      );

      const response = await apiClient.post("/v1/users/apply-artist", form, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        alert("전환 신청 되었습니다.");
        window.location.href = "/my/info";
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};

const openNicePopup = async () => {
  await open({
    type: "changePhone",
    onPopupClose: () => {
      const verify_data = JSON.parse(localStorage.getItem("verify_data"));
      localStorage.removeItem("verify_data");

      if (!verify_data || !user.value) {
        return;
      }

      if (
        user.value.name !== verify_data.name ||
        user.value.birthDate !== verify_data.birthDate
      ) {
        alert("변경하고자 하는 휴대폰 번호가 본인이 아닙니다.");
        return;
      }

      user.value.phoneNum = verify_data.phoneNum;
    },
  });
};

// 데이터 로드
const loadProfile = async () => {
  try {
    const response = await apiClient.get("/v1/users/me");
    user.value = response.data; // 사용자 데이터 로드
    //console.log("loadProfile", user.value);
    checkUserPermissions();
  } catch (error) {
    error.value = error;
  } finally {
    loading.value = false;
  }
};

const checkUserPermissions = () => {
  if (!user.value) return;

  isArtist.value = user.value.roleType.startsWith("ARTIST");
  canApplyArtist.value =
    user.value.roleType === "MEMBER" ||
    user.value.roleType === "ARTIST_PENDING_RETRY";

  if (!canApplyArtist.value || !route.meta.isApplyArtist) return;
  isApplyArtist.value = true;
  if (user.value.roleType === "MEMBER") user.value.roleType = "ARTIST_PENDING";
  validateForm();
};

// 비밀번호 변경 시작 //
const currentPassword = ref("");
const password1 = ref("");
const password2 = ref("");
const currentPasswordError = ref("");
const passwordError1 = ref("");
const passwordError2 = ref("");
const passwordFieldType = ref("password");
const password1FieldType = ref("password");
const password2FieldType = ref("password");
const togglePasswordVisibility = () => {
  passwordFieldType.value =
    passwordFieldType.value === "password" ? "text" : "password";
};
const togglePassword1Visibility = () => {
  password1FieldType.value =
    password1FieldType.value === "password" ? "text" : "password";
};
const togglePassword2Visibility = () => {
  password2FieldType.value =
    password2FieldType.value === "password" ? "text" : "password";
};

const isCheckCurrentPasswordSuccess = ref(false);

const validateCurrentPasswordForm = async () => {
  isCheckCurrentPasswordSuccess.value = false;
  const accountPwdRegex =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~]).{8,16}$/;

  if (!accountPwdRegex.test(currentPassword.value)) {
    currentPasswordError.value =
      "8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.";
    return false;
  } else {
    currentPasswordError.value = "";
    return true;
  }
};

const checkCurrentPassword = async (currentPassword) => {
  if (!(await validateCurrentPasswordForm())) {
    return;
  }

  try {
    btnSpinner.value = true;
    const response = await apiClient.post(
      "/v1/users/validate-current-password",
      {
        currentPassword: currentPassword,
      }
    );
    if (!response.data) {
      currentPasswordError.value = "현재 비밀번호가 틀렸습니다.";
    } else {
      currentPasswordError.value = "";
      isCheckCurrentPasswordSuccess.value = true;
    }
  } catch (error) {
    console.error("비밀밀번호 확인 실패했습니다.", error);
  } finally {
    btnSpinner.value = false;
  }
};

const validateAccountPwdForm = async () => {
  const accountPwdRegex =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~]).{8,16}$/;

  if (!accountPwdRegex.test(password1.value)) {
    passwordError1.value =
      "8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.";
    return false;
  } else {
    passwordError1.value = "";
  }

  if (password1.value !== password2.value) {
    passwordError2.value = "비밀번호가 일치하지 않습니다.";
    return false;
  } else {
    passwordError2.value = "";
  }
  return true;
};

const updatePassword = async () => {
  const isValid1 = await validateAccountPwdForm();
  if (!isValid1) {
    return;
  }

  if (!isCheckCurrentPasswordSuccess.value) {
    currentPasswordError.value = "현재 비밀번호를 확인해 주세요.";
    return;
  }

  if (confirm("비밀 번호를 수정 하시겠습니까?")) {
    try {
      loading.value = true;
      const response = await apiClient.put("/v1/users/password/update", {
        currentPassword: currentPassword.value,
        password: password1.value,
      });
      if (response.status === 200) {
        alert("비밀 번호가가 수정 되었습니다.");
        window.location.reload();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};

const emit = defineEmits(["mode-change"]);
const findPassword = (newMode) => {
  emit("mode-change", { tap: "findPassword", id: user.value.accountId });
};

// 비빌번호 변경 끝 //

// 회원 탈퇴 시작 //
const isModalVisible = ref(false);

const openModal = () => {
  isModalVisible.value = true;
};

const closeModal = () => {
  isModalVisible.value = false;
};

const handleWithdrawal = () => {
  // 탈퇴 로직 추가
  console.log("회원 탈퇴 처리 추가하기");
  closeModal();
};

// 회원 탈퇴 끝 //

onMounted(() => {
  loadProfile();
});
</script>